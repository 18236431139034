/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Oasis Nexus API V1
 * An API for accessing indexed data from the Oasis Network.
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import getStatusMutator from '../replaceNetworkWithBaseURL';
import GetConsensusBlocksMutator from '../replaceNetworkWithBaseURL';
import GetConsensusBlocksHeightMutator from '../replaceNetworkWithBaseURL';
import GetConsensusTransactionsMutator from '../replaceNetworkWithBaseURL';
import GetConsensusTransactionsTxHashMutator from '../replaceNetworkWithBaseURL';
import GetConsensusEventsMutator from '../replaceNetworkWithBaseURL';
import GetConsensusEntitiesMutator from '../replaceNetworkWithBaseURL';
import GetConsensusEntitiesEntityIdMutator from '../replaceNetworkWithBaseURL';
import GetConsensusEntitiesEntityIdNodesMutator from '../replaceNetworkWithBaseURL';
import GetConsensusEntitiesEntityIdNodesNodeIdMutator from '../replaceNetworkWithBaseURL';
import GetConsensusValidatorsMutator from '../replaceNetworkWithBaseURL';
import GetConsensusValidatorsEntityIdMutator from '../replaceNetworkWithBaseURL';
import GetConsensusAccountsMutator from '../replaceNetworkWithBaseURL';
import GetConsensusAccountsAddressMutator from '../replaceNetworkWithBaseURL';
import GetConsensusAccountsAddressDelegationsMutator from '../replaceNetworkWithBaseURL';
import GetConsensusAccountsAddressDelegationsToMutator from '../replaceNetworkWithBaseURL';
import GetConsensusAccountsAddressDebondingDelegationsMutator from '../replaceNetworkWithBaseURL';
import GetConsensusAccountsAddressDebondingDelegationsToMutator from '../replaceNetworkWithBaseURL';
import GetConsensusEpochsMutator from '../replaceNetworkWithBaseURL';
import GetConsensusEpochsEpochMutator from '../replaceNetworkWithBaseURL';
import GetConsensusProposalsMutator from '../replaceNetworkWithBaseURL';
import GetConsensusProposalsProposalIdMutator from '../replaceNetworkWithBaseURL';
import GetConsensusProposalsProposalIdVotesMutator from '../replaceNetworkWithBaseURL';
import GetRuntimeBlocksMutator from '../replaceNetworkWithBaseURL';
import GetRuntimeTransactionsMutator from '../replaceNetworkWithBaseURL';
import GetRuntimeTransactionsTxHashMutator from '../replaceNetworkWithBaseURL';
import GetRuntimeEventsMutator from '../replaceNetworkWithBaseURL';
import GetRuntimeEvmTokensMutator from '../replaceNetworkWithBaseURL';
import GetRuntimeEvmTokensAddressMutator from '../replaceNetworkWithBaseURL';
import GetRuntimeEvmTokensAddressHoldersMutator from '../replaceNetworkWithBaseURL';
import GetRuntimeEvmTokensAddressNftsMutator from '../replaceNetworkWithBaseURL';
import GetRuntimeEvmTokensAddressNftsIdMutator from '../replaceNetworkWithBaseURL';
import GetRuntimeAccountsAddressMutator from '../replaceNetworkWithBaseURL';
import GetRuntimeAccountsAddressNftsMutator from '../replaceNetworkWithBaseURL';
import GetRuntimeStatusMutator from '../replaceNetworkWithBaseURL';
import GetLayerStatsTxVolumeMutator from '../replaceNetworkWithBaseURL';
import GetLayerStatsActiveAccountsMutator from '../replaceNetworkWithBaseURL';
export type GetLayerStatsActiveAccountsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * The size of the step between returned statistic windows, in seconds.
The backend supports a limited number of step sizes: 300 (5 minutes) and
86400 (1 day). Requests with other values may be rejected.

 */
window_step_seconds?: number;
};

export type GetLayerStatsTxVolumeParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * The size of windows into which the statistic is grouped, in seconds.
The backend supports a limited number of window sizes: 300 (5 minutes) and
86400 (1 day). Requests with other values may be rejected.

 */
window_size_seconds?: number;
/**
 * The size of the step between returned statistic windows, in seconds.
The backend supports a limited number of step sizes: 300 (5 minutes) and
86400 (1 day). Requests with other values may be rejected.

 */
window_step_seconds?: number;
};

export type GetRuntimeAccountsAddressNftsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * Only return NFT instances from the token contract at the given staking address.
 */
token_address?: StakingAddress;
};

export type GetRuntimeEvmTokensAddressNftsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
};

export type GetRuntimeEvmTokensAddressHoldersParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
};

export type GetRuntimeEvmTokensParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * A filter on the name, the name or symbol must contain this value as a substring.
 */
name?: string;
};

export type GetRuntimeEventsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * A filter on block round.
 */
block?: number;
/**
 * A filter on transaction index. The returned events all need to originate
from a transaction that appeared in `tx_index`-th position in the block.
It is invalid to specify this filter without also specifying a `block`.
Specifying `tx_index` and `round` is an alternative to specifying `tx_hash`;
either works to fetch events from a specific transaction.

 */
tx_index?: number;
/**
 * A filter on the hash of the transaction that originated the events.
Specifying `tx_index` and `round` is an alternative to specifying `tx_hash`;
either works to fetch events from a specific transaction.
This can be an Ethereum transaction hash; the query will compare against
both a transaction's regular tx_hash and eth_tx_hash (if it exists).

 */
tx_hash?: string;
/**
 * A filter on the event type.
 */
type?: RuntimeEventType;
/**
 * A filter on related accounts. Every returned event will refer to
this account. For example, for a `accounts.Transfer` event, this will be
the sender or the recipient of tokens.

 */
rel?: string;
/**
 * A filter on the evm log signatures.
Note: The filter will only match on parsed (verified) EVM events.

 */
evm_log_signature?: string;
/**
 * A filter on a smart contract. Every returned event will have been
emitted by the contract at this Oasis address.

 */
contract_address?: string;
/**
 * A filter on NFT events. Every returned event will be specifically
about this NFT instance ID. You must specify the contract_address
filter with this filter.
Currently this only supports ERC-721 Transfer events.
This may expand to support other event types in the future.
If you want only ERC-721 Transfer events, specify
evm_log_signature=ddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef
to avoid inadvertently getting other event types if they are
supported later.
Using an evm_log_signature filter with this set to any other value
will match no events.

 */
nft_id?: string;
};

export type GetRuntimeTransactionsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * A filter on block round.
 */
block?: number;
/**
 * A filter on minimum transaction time, inclusive.
 */
after?: string;
/**
 * A filter on maximum transaction time, exclusive.
 */
before?: string;
/**
 * A filter on related accounts. Every returned transaction will refer to
this account in a way. For example, for an `accounts.Transfer` tx, this will be
the sender or the recipient of tokens.
Nexus detects related accounts inside EVM transactions and events on a
best-effort basis. For example, it inspects ERC20 methods inside `evm.Call` txs.
However, you must provide the Oasis-style derived address here, not the Eth address.
See `AddressPreimage` for more info on Oasis-style vs Eth addresses.

 */
rel?: StakingAddress;
};

export type GetRuntimeBlocksParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * A filter on minimum block height, inclusive.
 */
from?: number;
/**
 * A filter on maximum block height, inclusive.
 */
to?: number;
/**
 * A filter on minimum block time, inclusive.
 */
after?: string;
/**
 * A filter on maximum block time, exclusive.
 */
before?: string;
/**
 * A filter on the block hash.
 */
hash?: string;
};

export type GetConsensusProposalsProposalIdVotesParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
};

export type GetConsensusProposalsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * Filter on the submitter of the proposal.
 */
submitter?: StakingAddress;
/**
 * Filter on the state of the proposal.
 */
state?: ProposalState;
};

export type GetConsensusEpochsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
};

export type GetConsensusAccountsAddressDebondingDelegationsToParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
};

export type GetConsensusAccountsAddressDebondingDelegationsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
};

export type GetConsensusAccountsAddressDelegationsToParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
};

export type GetConsensusAccountsAddressDelegationsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
};

export type GetConsensusAccountsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * A filter on the minimum available account balance.
 */
minAvailable?: TextBigInt;
/**
 * A filter on the maximum available account balance.
 */
maxAvailable?: TextBigInt;
/**
 * A filter on the minimum active escrow account balance.
 */
minEscrow?: TextBigInt;
/**
 * A filter on the maximum active escrow account balance.
 */
maxEscrow?: TextBigInt;
/**
 * A filter on the minimum debonding account balance.
 */
minDebonding?: TextBigInt;
/**
 * A filter on the maximum debonding account balance.
 */
maxDebonding?: TextBigInt;
/**
 * A filter on the minimum total account balance.
 */
minTotalBalance?: TextBigInt;
/**
 * A filter on the maximum total account balance.
 */
maxTotalBalance?: TextBigInt;
};

export type GetConsensusValidatorsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
};

export type GetConsensusEntitiesEntityIdNodesParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
};

export type GetConsensusEntitiesParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
};

export type GetConsensusEventsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * A filter on block height.
 */
block?: number;
/**
 * A filter on transaction index. The returned events all need to originate
from a transaction that appeared in `tx_index`-th position in the block.
It is invalid to specify this filter without also specifying a `block`.
Specifying `tx_index` and `block` is an alternative to specifying `tx_hash`;
either works to fetch events from a specific transaction.

 */
tx_index?: number;
/**
 * A filter on the hash of the transaction that originated the events.
Specifying `tx_index` and `block` is an alternative to specifying `tx_hash`;
either works to fetch events from a specific transaction.

 */
tx_hash?: string;
/**
 * A filter on related accounts. Every returned event will refer to
this account. For example, for a `Transfer` event, this will be the
the sender or the recipient of tokens.

 */
rel?: StakingAddress;
/**
 * A filter on the event type.
 */
type?: ConsensusEventType;
};

export type GetConsensusTransactionsParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * A filter on block height.
 */
block?: number;
/**
 * A filter on transaction method.
 */
method?: ConsensusTxMethod;
/**
 * A filter on transaction sender.
 */
sender?: StakingAddress;
/**
 * A filter on related accounts.
 */
rel?: string;
/**
 * A filter on minimum transaction fee, inclusive.
 */
minFee?: TextBigInt;
/**
 * A filter on maximum transaction fee, inclusive.
 */
maxFee?: TextBigInt;
/**
 * A filter on transaction status code.
 */
code?: number;
/**
 * A filter on minimum transaction time, inclusive.
 */
after?: string;
/**
 * A filter on maximum transaction time, exclusive.
 */
before?: string;
};

export type GetConsensusBlocksParams = {
/**
 * The maximum numbers of items to return.

 */
limit?: number;
/**
 * The number of items to skip before starting to collect the result set.

 */
offset?: number;
/**
 * A filter on minimum block height, inclusive.
 */
from?: number;
/**
 * A filter on maximum block height, inclusive.
 */
to?: number;
/**
 * A filter on minimum block time, inclusive.
 */
after?: string;
/**
 * A filter on maximum block time, exclusive.
 */
before?: string;
/**
 * A filter on the block hash.
 */
hash?: string;
};

/**
 * An empty response indicating that the requested resource was not found.
 */
export type NotFoundErrorResponse = unknown;

export type HumanReadableErrorResponse = {
  /** An error message. */
  msg: string;
};

export interface ActiveAccounts {
  /** The number of active accounts for the 24hour window ending at window_end. */
  active_accounts: number;
  /** The date for the end of the daily active accounts measurement window. */
  window_end: string;
}

/**
 * A list of daily unique active account windows.

 */
export interface ActiveAccountsList {
  window_size_seconds: number;
  /** The list of daily unique active account windows. */
  windows: ActiveAccounts[];
}

export interface TxVolume {
  /** The transaction volume for this window. */
  tx_volume: number;
  /** The end timestamp for this daily transaction volume measurement. */
  window_end: string;
}

/**
 * A list of daily transaction volumes.

 */
export interface TxVolumeList {
  window_size_seconds: number;
  /** The list of daily transaction volumes. */
  windows: TxVolume[];
}

export interface AccountStats {
  /** The total number of transactions this account was involved with. */
  num_txns: number;
  /** The total number of tokens received, in base units. */
  total_received: TextBigInt;
  /** The total number of tokens sent, in base units. */
  total_sent: TextBigInt;
}

export interface EvmNft {
  /** Describes the asset which this NFT represents */
  description?: string;
  /** The instance ID of this NFT within the collection represented by `token`. */
  id: TextBigInt;
  /** A URI pointing to a resource with mime type image/* representing
the asset which this NFT represents. (Additional
non-descriptive text from ERC-721 omitted.)
 */
  image?: string;
  /** A metadata document for this NFT instance.
Currently only ERC-721 is supported, where the document is an Asset Metadata from the ERC721 Metadata JSON Schema.
 */
  metadata?: unknown;
  metadata_accessed?: string;
  metadata_uri?: string;
  /** Identifies the asset which this NFT represents */
  name?: string;
  /** The total number of transfers of this NFT instance.
 */
  num_transfers?: number;
  /** The Oasis address of this NFT instance's owner. */
  owner?: Address;
  /** The Ethereum address of this NFT instance's owner. */
  owner_eth?: string;
  token: EvmToken;
}

/**
 * A list of NFT instances.
 */
export type EvmNftListAllOf = {
  /** A list of L2 EVM NFT (ERC-721, ...) instances. */
  evm_nfts: EvmNft[];
};

export type EvmNftList = List & EvmNftListAllOf;

export interface EvmToken {
  /** The Oasis address of this token's contract. */
  contract_addr: string;
  /** The number of least significant digits in base units that should be displayed as
decimals when displaying tokens. `tokens = base_units / (10**decimals)`.
Affects display only. Often equals 18, to match ETH.
 */
  decimals?: number;
  /** The Ethereum address of this token's contract. */
  eth_contract_addr: string;
  /** Whether the contract has been successfully verified by Sourcify.
Additional information on verified contracts is available via
the `/{runtime}/accounts/{address}` endpoint.
 */
  is_verified: boolean;
  /** Name of the token, as provided by token contract's `name()` method. */
  name?: string;
  /** The number of addresses that have a nonzero balance of this token.
 */
  num_holders: number;
  /** The total number of transfers of this token.
 */
  num_transfers?: number;
  /** Symbol of the token, as provided by token contract's `symbol()` method. */
  symbol?: string;
  /** The total number of base units available. */
  total_supply?: TextBigInt;
  /** The heuristically determined interface that the token contract implements.
A less specialized variant of the token might be detected; for example, an
ERC-1363 token might be labeled as ERC-20 here. If the type cannot be
detected or is not supported, this field will be null/absent.
 */
  type: EvmTokenType;
}

/**
 * A list of tokens in a runtime.
 */
export type EvmTokenListAllOf = {
  /** A list of L2 EVM tokens (ERC-20, ERC-721, ...). */
  evm_tokens: EvmToken[];
};

export type EvmTokenList = List & EvmTokenListAllOf;

/**
 * The type of a EVM token.

 */
export type EvmTokenType = typeof EvmTokenType[keyof typeof EvmTokenType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EvmTokenType = {
  ERC20: 'ERC20',
  ERC721: 'ERC721',
} as const;

export interface RuntimeStatus {
  /** The number of compute nodes that are registered and can run the runtime. */
  active_nodes: number;
  /** The height of the most recent indexed block (also sometimes referred to as "round") for this runtime. Query a synced Oasis node for the latest block produced. */
  latest_block: number;
  /** The RFC 3339 formatted consensus time of when the latest indexed block for this runtime was produced. */
  latest_block_time: string;
  /** The number of milliseconds since Nexus processed the latest block. */
  latest_update_age_ms: number;
}

export interface RuntimeAccount {
  /** The staking address for this account. */
  address: string;
  address_preimage?: AddressPreimage;
  /** The balance(s) of this account in this runtime. Most runtimes use only one denomination, and thus
produce only one balance here. These balances do not include "layer (n+1) tokens", i.e. tokens
managed by smart contracts deployed in this runtime. For example, in EVM-compatible runtimes,
this does not include ERC-20 tokens
 */
  balances: RuntimeSdkBalance[];
  /** The balances of this account in each runtime, as managed by EVM smart contracts (notably, ERC-20).
NOTE: This field is limited to 1000 entries. If you need more, please let us know in a GitHub issue.
 */
  evm_balances: RuntimeEvmBalance[];
  /** Data on the EVM smart contract associated with this account address. Only present for accounts
that represent a smart contract on EVM.
 */
  evm_contract?: RuntimeEvmContract;
  stats: AccountStats;
}

export interface RuntimeTransactionEncryptionEnvelope {
  /** The base64-encoded encrypted transaction data. */
  data?: string;
  /** The base64-encoded nonce used to encrypt the transaction data. */
  data_nonce?: string;
  /** The format of the encrypted evm transaction envelope. */
  format: CallFormat;
  /** The base64-encoded public key used to encrypt the transaction. */
  public_key?: string;
  /** The base64-encoded encrypted result data. */
  result?: string;
  /** The base64-encoded nonce used to encrypt the transaction results. */
  result_nonce?: string;
}

/**
 * The method call body. May be null if the transaction was malformed.
 */
export type RuntimeTransactionBody = { [key: string]: any };

/**
 * A runtime transaction.

 */
export interface RuntimeTransaction {
  /** A reasonable "amount" associated with this transaction, if
applicable. The meaning varies based on the transaction method.
Usually in native denomination, ParaTime units. As a string.
 */
  amount?: string;
  /** The method call body. May be null if the transaction was malformed. */
  body?: RuntimeTransactionBody;
  /** The fee that was charged for the transaction execution (total, native denomination,
ParaTime base units, as a string).
For EVM transactions this is calculated as `gas_price * gas_used`, where `gas_price = fee / gas_limit`, for compatibility with Ethereum.
For other transactions this equals to `fee`.
 */
  charged_fee: string;
  /** The data relevant to the encrypted transaction. Only present for encrypted
transactions in confidential EVM runtimes like Sapphire.
Note: The term "envelope" in this context refers to the [Oasis-style encryption envelopes](https://github.com/oasisprotocol/oasis-sdk/blob/c36a7ee194abf4ca28fdac0edbefe3843b39bf69/runtime-sdk/src/types/callformat.rs)
which differ slightly from [digital envelopes](hhttps://en.wikipedia.org/wiki/Hybrid_cryptosystem#Envelope_encryption).
 */
  encryption_envelope?: RuntimeTransactionEncryptionEnvelope;
  /** Error details of a failed transaction. */
  error?: TxError;
  /** The Ethereum cryptographic hash of this transaction's encoding.
Absent for non-Ethereum-format transactions.
 */
  eth_hash?: string;
  /** The name of the smart contract function called by the transaction.
Only present for `evm.log` transaction calls to contracts that have been verified.
 */
  evm_fn_name?: string;
  /** The decoded parameters with which the smart contract function was called.
Only present for `evm.log` transaction calls to contracts that have been verified.
 */
  evm_fn_params?: EvmAbiParam[];
  /** The fee that this transaction's sender committed to pay to execute
it (total, native denomination, ParaTime base units, as a string).
 */
  fee: string;
  /** The maximum gas that this transaction's sender committed to use to
execute it.
 */
  gas_limit: number;
  /** The total gas used by the transaction. */
  gas_used: number;
  /** The Oasis cryptographic hash of this transaction's encoding. */
  hash: string;
  /** The 0-based index of this transaction in the block. */
  index: number;
  /** Whether this transaction likely represents a native token transfer.
This is based on a heuristic, and can change at any time without warning and possibly without updating the documentation.
The current heuristic sets this to `true` for:
 - Transactions with method "accounts.Transfer". Those are always native token transfers.
 - Transactions with method "evm.Call" that have no `data` field in their `body`. Those tend to be transfers, but the runtimes provides no reliable visibility into whether a transfer happened.
Note: Other transactions with method "evm.Call", and possibly "evm.Create", may also be (or include) native token transfers. The heuristic will be `false` for those.
 */
  is_likely_native_token_transfer?: boolean;
  /** The method that was called. Defined by the runtime. In theory, this could be any string as the runtimes evolve.
In practice, Nexus currently expects only the following methods:
  - "accounts.Transfer"
  - "consensus.Deposit"
  - "consensus.Withdraw"
  - "consensus.Delegate"
  - "consensus.Undelegate"
  - "evm.Create"
  - "evm.Call"
May be null if the transaction was malformed or encrypted.
 */
  method?: string;
  /** The nonce used with this transaction's 0th signer, to prevent replay. */
  nonce_0: number;
  /** The block round at which this transaction was executed. */
  round: number;
  /** The Oasis address of this transaction's 0th signer.
Unlike Ethereum, Oasis natively supports multiple-signature transactions.
However, the great majority of transactions only have a single signer in practice.
Retrieving the other signers is currently not supported by this API.
 */
  sender_0: Address;
  /** The Ethereum address of this transaction's 0th signer.
 */
  sender_0_eth?: string;
  /** The total byte size of the transaction. */
  size: number;
  /** Whether this transaction successfully executed.
Can be absent (meaning "unknown") for confidential runtimes.
 */
  success?: boolean;
  /** The second-granular consensus time when this tx's block was proposed. */
  timestamp: string;
  /** A reasonable "to" Oasis address associated with this transaction,
if applicable. The meaning varies based on the transaction method. Some notable examples:
  - For `method = "accounts.Transfer"`, this is the paratime account receiving the funds.
  - For `method = "consensus.Deposit"`, this is the paratime account receiving the funds.
  - For `method = "consensus.Withdraw"`, this is the consensus (!) account receiving the funds.
  - For `method = "consensus.Delegate"`, this is the consensus (!) account receiving the funds.
  - For `method = "consensus.Undelegate"`, this is the consensus (!) account to which funds were previously delegated. Note that this corresponds with the `.from` field in the transaction body.
  - For `method = "evm.Create"`, this is the address of the newly created smart contract.
  - For `method = "evm.Call"`, this is the address of the called smart contract
 */
  to?: Address;
  /** A reasonable "to" Ethereum address associated with this transaction,
 */
  to_eth?: string;
}

/**
 * A list of runtime transactions.

 */
export type RuntimeTransactionListAllOf = {
  transactions: RuntimeTransaction[];
};

export type RuntimeTransactionList = List & RuntimeTransactionListAllOf;

export type RuntimeEvmContractVerificationSourceFilesItem = { [key: string]: any };

/**
 * The smart contract's [metadata.json](https://docs.soliditylang.org/en/latest/metadata.html) file in JSON format as defined by Solidity.
Includes the smart contract's [ABI](https://docs.soliditylang.org/en/develop/abi-spec.html).

 */
export type RuntimeEvmContractVerificationCompilationMetadata = { [key: string]: any };

export interface RuntimeEvmContractVerification {
  /** The smart contract's [metadata.json](https://docs.soliditylang.org/en/latest/metadata.html) file in JSON format as defined by Solidity.
Includes the smart contract's [ABI](https://docs.soliditylang.org/en/develop/abi-spec.html).
 */
  compilation_metadata?: RuntimeEvmContractVerificationCompilationMetadata;
  /** Array of all contract source files, in JSON format as returned by [Sourcify](https://sourcify.dev/server/api-docs/#/Repository/get_files_any__chain___address_).
 */
  source_files?: RuntimeEvmContractVerificationSourceFilesItem[];
}

export interface RuntimeEvmContract {
  /** The creation bytecode of the smart contract. This includes the constructor logic
and the constructor parameters. When run, this code generates the runtime bytecode.
Can be omitted for contracts that were created by another contract, as opposed
to a direct `Create` call.
 */
  creation_bytecode?: string;
  /** The Oasis cryptographic hash of the transaction that created the smart contract.
Can be omitted for contracts that were created by another contract, as opposed
to a direct `Create` call.
 */
  creation_tx?: string;
  /** The Ethereum transaction hash of the transaction in `creation_tx`.
Encoded as a lowercase hex string.
 */
  eth_creation_tx?: string;
  /** The total amount of gas used to create or call this contract. */
  gas_used: number;
  /** The runtime bytecode of the smart contract. This is the code stored on-chain that
describes a smart contract. Every contract has this info, but Nexus fetches
it separately, so the field may be missing for very fresh contracts (or if the fetching
process is stalled).
 */
  runtime_bytecode?: string;
  /** Additional information obtained from contract verification. Only available for smart
contracts that have been verified successfully by Sourcify.
 */
  verification?: RuntimeEvmContractVerification;
}

/**
 * Details about the EVM token involved in the event, if any.

 */
export interface EvmEventToken {
  /** The number of least significant digits in base units that should be displayed as
decimals when displaying tokens. `tokens = base_units / (10**decimals)`.
Affects display only. Often equals 18, to match ETH.
 */
  decimals?: number;
  /** Symbol of the token, as provided by token contract's `symbol()` method. */
  symbol?: string;
  type?: EvmTokenType;
}

/**
 * A decoded parameter of an event or error emitted from an EVM runtime.
Values of EVM type `int128`, `uint128`, `int256`, `uint256`, `fixed`, and `ufixed` are represented as strings.
Values of EVM type `address` and `address payable` are represented as lowercase hex strings with a "0x" prefix.
Values of EVM type `bytes` and `bytes<N>` are represented as base64 strings.
Values of other EVM types (integer types, strings, arrays, etc.) are represented as their JSON counterpart.

 */
export interface EvmAbiParam {
  /** The solidity type of the parameter. */
  evm_type: string;
  /** The parameter name. */
  name: string;
  /** The parameter value. */
  value: unknown;
}

export type RuntimeEventType = typeof RuntimeEventType[keyof typeof RuntimeEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RuntimeEventType = {
  accountstransfer: 'accounts.transfer',
  accountsburn: 'accounts.burn',
  accountsmint: 'accounts.mint',
  consensus_accountsdeposit: 'consensus_accounts.deposit',
  consensus_accountswithdraw: 'consensus_accounts.withdraw',
  consensus_accountsdelegate: 'consensus_accounts.delegate',
  consensus_accountsundelegate_start: 'consensus_accounts.undelegate_start',
  consensus_accountsundelegate_done: 'consensus_accounts.undelegate_done',
  coregas_used: 'core.gas_used',
  evmlog: 'evm.log',
} as const;

/**
 * The decoded event contents. This spec does not encode the many possible types;
instead, see [the Go API](https://pkg.go.dev/github.com/oasisprotocol/oasis-sdk/client-sdk/go/modules).
This object will conform to one of the `*Event` types two levels down
the hierarchy (e.g. `MintEvent` from `accounts > Event > MintEvent`),
OR `evm > Event`.

 */
export type RuntimeEventBody = { [key: string]: any };

/**
 * An event emitted by the runtime layer
 */
export interface RuntimeEvent {
  /** The decoded event contents. This spec does not encode the many possible types;
instead, see [the Go API](https://pkg.go.dev/github.com/oasisprotocol/oasis-sdk/client-sdk/go/modules).
This object will conform to one of the `*Event` types two levels down
the hierarchy (e.g. `MintEvent` from `accounts > Event > MintEvent`),
OR `evm > Event`.
 */
  body: RuntimeEventBody;
  /** Ethereum trasnsaction hash of this event's originating transaction.
Absent if the event did not originate from an EVM transaction.
 */
  eth_tx_hash?: string;
  /** If the event type is `evm.log`, this field describes the human-readable type of 
evm event, e.g. `Transfer`. 
Absent if the event type is not `evm.log`.
 */
  evm_log_name?: string | null;
  /** The decoded `evm.log` event data.
Absent if the event type is not `evm.log`.
 */
  evm_log_params?: EvmAbiParam[];
  evm_token?: EvmEventToken;
  /** The block height at which this event was generated. */
  round: number;
  /** The second-granular consensus time of this event's block.
 */
  timestamp: string;
  /** Hash of this event's originating transaction.
Absent if the event did not originate from a transaction.
 */
  tx_hash?: string | null;
  /** 0-based index of this event's originating transaction within its block.
Absent if the event did not originate from a transaction.
 */
  tx_index?: number;
  /** The type of the event. */
  type: RuntimeEventType;
}

/**
 * A list of runtime events.

 */
export type RuntimeEventListAllOf = {
  events: RuntimeEvent[];
};

export type RuntimeEventList = List & RuntimeEventListAllOf;

/**
 * A ParaTime block.

 */
export interface RuntimeBlock {
  /** The total gas used by all transactions in the block. */
  gas_used: number;
  /** The block header hash. */
  hash: string;
  /** The number of transactions in the block. */
  num_transactions: number;
  /** The block round. */
  round: number;
  /** The total byte size of all transactions in the block. */
  size: number;
  /** The second-granular consensus time. */
  timestamp: string;
}

/**
 * A list of consensus blocks.

 */
export type RuntimeBlockListAllOf = {
  blocks: RuntimeBlock[];
};

export type RuntimeBlockList = List & RuntimeBlockListAllOf;

export interface ProposalVote {
  /** The staking address casting this vote. */
  address: string;
  /** The vote cast. */
  vote: string;
}

/**
 * A list of votes for a governance proposal.

 */
export type ProposalVotesAllOf = {
  /** The unique identifier of the proposal. */
  proposal_id: number;
  /** The list of votes for the proposal. */
  votes: ProposalVote[];
};

export type ProposalVotes = List & ProposalVotesAllOf;

/**
 * The state of the proposal.
 */
export type ProposalState = typeof ProposalState[keyof typeof ProposalState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProposalState = {
  active: 'active',
  passed: 'passed',
  failed: 'failed',
  rejected: 'rejected',
} as const;

/**
 * The target propotocol versions for this upgrade proposal.
 */
export interface ProposalTarget {
  consensus_protocol?: string;
  runtime_committee_protocol?: string;
  runtime_host_protocol?: string;
}

/**
 * A governance proposal.

 */
export interface Proposal {
  /** The proposal to cancel, if this proposal proposes
cancelling an existing proposal.
 */
  cancels?: number;
  /** The epoch at which voting for this proposal will close. */
  closes_at: number;
  /** The epoch at which this proposal was created. */
  created_at: number;
  /** The deposit attached to this proposal. */
  deposit: TextBigInt;
  /** The epoch at which the proposed upgrade will happen. */
  epoch?: number;
  /** The name of the upgrade handler. */
  handler?: string;
  /** The unique identifier of the proposal. */
  id: number;
  /** The number of invalid votes for this proposal, after tallying.
 */
  invalid_votes: TextBigInt;
  /** The base64 encoded raw cbor representing the updated parameters
which are to be changed by this 'parameters_change' proposal.
 */
  parameters_change?: string;
  /** The name of the module whose parameters are to be changed
by this 'parameters_change' proposal.
 */
  parameters_change_module?: string;
  state: ProposalState;
  /** The staking address of the proposal submitter. */
  submitter: string;
  target?: ProposalTarget;
}

/**
 * A list of governance proposals.

 */
export type ProposalListAllOf = {
  proposals: Proposal[];
};

export type ProposalList = List & ProposalListAllOf;

/**
 * A consensus epoch.

 */
export interface Epoch {
  /** The (inclusive) height at which this epoch ended. Omitted if the epoch is still active. */
  end_height?: number;
  /** The epoch number. */
  id: number;
  /** The (inclusive) height at which this epoch started. */
  start_height: number;
}

/**
 * A list of consensus epochs.

 */
export type EpochListAllOf = {
  epochs: Epoch[];
};

export type EpochList = List & EpochListAllOf;

export interface Allowance {
  /** The allowed account. */
  address: string;
  /** The amount allowed for the allowed account. */
  amount: TextBigInt;
}

/**
 * A consensus layer account.

 */
export interface Account {
  /** The staking address for this account. */
  address: string;
  /** The allowances made by this account. */
  allowances: Allowance[];
  /** The available balance, in base units. */
  available: TextBigInt;
  /** The debonding escrow balance, in base units. */
  debonding: TextBigInt;
  /** The debonding delegations balance, in base units.
For efficiency, this field is omitted when listing multiple-accounts.
 */
  debonding_delegations_balance?: TextBigInt;
  /** The delegations balance, in base units.
For efficiency, this field is omitted when listing multiple-accounts.
 */
  delegations_balance?: TextBigInt;
  /** The active escrow balance, in base units. */
  escrow: TextBigInt;
  /** A nonce used to prevent replay. */
  nonce: number;
}

/**
 * Balance of an account for a specific (implied) runtime and token.

 */
export interface BareTokenHolder {
  /** Number of tokens held, in base units. */
  balance: TextBigInt;
  /** The Ethereum address of the same account holder, if meaningfully defined. */
  eth_holder_address?: string;
  /** The Oasis address of the account holder. */
  holder_address: string;
}

/**
 * A list of token holders for a specific (implied) runtime and token.

 */
export type TokenHolderListAllOf = {
  holders: BareTokenHolder[];
};

export type TokenHolderList = List & TokenHolderListAllOf;

/**
 * Balance of an account for a specific runtime and EVM token.
 */
export interface RuntimeEvmBalance {
  /** Number of tokens held, in base units. */
  balance: TextBigInt;
  /** The Oasis address of this token's contract. */
  token_contract_addr: string;
  /** The EVM address of this token's contract. */
  token_contract_addr_eth: string;
  /** The number of decimals of precision for this token. */
  token_decimals: number;
  /** The name of the token. Not guaranteed to be unique across distinct EVM tokens. */
  token_name?: string;
  /** The token ticker symbol. Not guaranteed to be unique across distinct EVM tokens. */
  token_symbol?: string;
  token_type: EvmTokenType;
}

/**
 * Balance of an account for a specific runtime and oasis-sdk token (e.g. ROSE).
 */
export interface RuntimeSdkBalance {
  /** Number of tokens held, in base units. */
  balance: TextBigInt;
  /** The number of decimals of precision for this token. */
  token_decimals: number;
  /** The token ticker symbol. Unique across all oasis-sdk tokens in the same runtime. */
  token_symbol: string;
}

export type AddressDerivationContext = typeof AddressDerivationContext[keyof typeof AddressDerivationContext];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressDerivationContext = {
  'oasis-core/address:_staking': 'oasis-core/address: staking',
  'oasis-runtime-sdk/address:_secp256k1eth': 'oasis-runtime-sdk/address: secp256k1eth',
  'oasis-runtime-sdk/address:_sr25519': 'oasis-runtime-sdk/address: sr25519',
  'oasis-runtime-sdk/address:_multisig': 'oasis-runtime-sdk/address: multisig',
  'oasis-runtime-sdk/address:_module': 'oasis-runtime-sdk/address: module',
  'oasis-runtime-sdk/address:_runtime': 'oasis-runtime-sdk/address: runtime',
} as const;

/**
 * The data from which a consensus-style address (`oasis1...`)
was derived. Notably, for EVM runtimes like Sapphire,
this links the Oasis address and the Ethereum address.

Oasis addresses are derived from a piece of data, such as an ed25519
public key or an Ethereum address. For example, [this](https://github.com/oasisprotocol/oasis-sdk/blob/b37e6da699df331f5a2ac62793f8be099c68469c/client-sdk/go/helpers/address.go#L90-L91)
is how an Ethereum is converted to an Oasis address. The type of underlying data usually also
determines how the signatuers for this address are verified.

Consensus supports only "staking addresses" (`context="oasis-core/address: staking"`
below; always ed25519-backed).
Runtimes support all types. This means that every consensus address is also
valid in every runtime. For example, in EVM runtimes, you can use staking
addresses, but only with Oasis tools (e.g. a wallet); EVM contracts such as
ERC20 tokens or tools such as Metamask cannot interact with staking addresses.

 */
export interface AddressPreimage {
  /** The base64-encoded data from which the Oasis address was derived.
When `context = "oasis-runtime-sdk/address: secp256k1eth"`, this
is the Ethereum address (in base64, not hex!).
 */
  address_data: string;
  /** The method by which the Oasis address was derived from `address_data`.
 */
  context: AddressDerivationContext;
  /** Version of the `context`. */
  context_version?: number | null;
}

/**
 * A list of consensus layer accounts.

 */
export type AccountListAllOf = {
  accounts: Account[];
};

export type AccountList = List & AccountListAllOf;

/**
 * A node registered at the consensus layer.

 */
export interface Node {
  /** The unique identifier of this node as a consensus member */
  consensus_pubkey: string;
  /** The public key identifying the entity controlling this node.
 */
  entity_id: string;
  /** The epoch in which this node's commitment expires. */
  expiration: number;
  /** The public key identifying this node. */
  id: string;
  /** The unique identifier of this node on the P2P transport. */
  p2p_pubkey: string;
  /** A bitmask representing this node's roles. */
  roles: string;
  /** The public key that will be used for establishing TLS connections
upon rotation.
 */
  tls_next_pubkey: string;
  /** The public key used for establishing TLS connections. */
  tls_pubkey: string;
}

/**
 * A list of nodes registered at the consensus layer.

 */
export type NodeListAllOf = {
  entity_id: string;
  nodes: Node[];
};

export type NodeList = List & NodeListAllOf;

export interface ValidatorMedia {
  /** An email address for the validator. */
  email_address?: string;
  /** A logo type. */
  logotype?: string;
  /** The human-readable name of this validator. */
  name?: string;
  /** An Telegram handle. */
  tg_chat?: string;
  /** A Twitter handle. */
  twitter_acc?: string;
  /** An URL associated with the entity. */
  website_link?: string;
}

export interface ValidatorCommissionBound {
  epoch_end: number;
  epoch_start: number;
  lower: number;
  upper: number;
}

/**
 * An validator registered at the consensus layer.

 */
export interface Validator {
  /** Whether the entity is part of validator set (top <scheduler.params.max_validators> by stake). */
  active: boolean;
  current_commission_bound: ValidatorCommissionBound;
  /** Commission rate. */
  current_rate: number;
  /** The staking address identifying this Validator. */
  entity_address: string;
  /** The public key identifying this Validator. */
  entity_id: string;
  /** The amount staked. */
  escrow: TextBigInt;
  media?: ValidatorMedia;
  /** The public key identifying this Validator's node. */
  node_id: string;
  /** Whether the entity has a node that is registered for being a validator, node is up to date, and has successfully registered itself. It may or may not be part of validator set. */
  status: boolean;
}

/**
 * A list of validators registered at the consensus layer.

 */
export type ValidatorListAllOf = {
  validators: Validator[];
};

export type ValidatorList = List & ValidatorListAllOf;

/**
 * An entity registered at the consensus layer.

 */
export interface Entity {
  /** The staking address belonging to this entity; derived from the entity's public key. */
  address: string;
  /** The public key identifying this entity. */
  id: string;
  /** The vector of nodes owned by this entity. */
  nodes: string[];
}

/**
 * A list of entities registered at the consensus layer.

 */
export type EntityListAllOf = {
  entities: Entity[];
};

export type EntityList = List & EntityListAllOf;

/**
 * The event contents. This spec does not encode the many possible types;
instead, see [the Go API](https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go/consensus/api/transaction/results#Event) of oasis-core.
This object will conform to one of the `*Event` types two levels down
the hierarchy, e.g. `TransferEvent` from `Event > staking.Event > TransferEvent`

 */
export type ConsensusEventBody = { [key: string]: any };

/**
 * A list of consensus events.

 */
export type ConsensusEventListAllOf = {
  events: ConsensusEvent[];
};

export type ConsensusEventList = List & ConsensusEventListAllOf;

export type ConsensusEventType = typeof ConsensusEventType[keyof typeof ConsensusEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsensusEventType = {
  governanceproposal_executed: 'governance.proposal_executed',
  governanceproposal_finalized: 'governance.proposal_finalized',
  governanceproposal_submitted: 'governance.proposal_submitted',
  governancevote: 'governance.vote',
  registryentity: 'registry.entity',
  registrynode_unfrozen: 'registry.node_unfrozen',
  registrynode: 'registry.node',
  registryruntime: 'registry.runtime',
  registryruntime_suspended: 'registry.runtime_suspended',
  roothashexecution_discrepancy: 'roothash.execution_discrepancy',
  roothashexecutor_committed: 'roothash.executor_committed',
  roothashfinalized: 'roothash.finalized',
  stakingallowance_change: 'staking.allowance_change',
  stakingburn: 'staking.burn',
  stakingescrowadd: 'staking.escrow.add',
  stakingescrowdebonding_start: 'staking.escrow.debonding_start',
  stakingescrowreclaim: 'staking.escrow.reclaim',
  stakingescrowtake: 'staking.escrow.take',
  stakingtransfer: 'staking.transfer',
} as const;

/**
 * An event emitted by the consensus layer.

 */
export interface ConsensusEvent {
  /** The block height at which this event was generated. */
  block: number;
  /** The event contents. This spec does not encode the many possible types;
instead, see [the Go API](https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go/consensus/api/transaction/results#Event) of oasis-core.
This object will conform to one of the `*Event` types two levels down
the hierarchy, e.g. `TransferEvent` from `Event > staking.Event > TransferEvent`
 */
  body: ConsensusEventBody;
  /** Hash of this event's originating transaction.
Absent if the event did not originate from a transaction.
 */
  tx_hash?: string | null;
  /** 0-based index of this event's originating transaction within its block.
Absent if the event did not originate from a transaction.
 */
  tx_index?: number | null;
  /** The type of the event. */
  type: ConsensusEventType;
}

export interface TxError {
  /** The status code of a failed transaction. */
  code: number;
  /** The message of a failed transaction.
This field, like `code` and `module`, can represent an error that originated
anywhere in the paratime, i.e. either inside or outside a smart contract.

A common special case worth calling out: When the paratime is 
EVM-compatible (e.g. Emerald or Sapphire) and the error originates 
inside a smart contract (using `revert` in solidity), the following 
will be true:
- `module` will be "evm" and `code` will be 8; see [here](https://github.com/oasisprotocol/oasis-sdk/blob/runtime-sdk/v0.8.3/runtime-sdk/modules/evm/src/lib.rs#L128) for other possible errors in the `evm` module.
- `message` will contain the best-effort human-readable revert reason.
 */
  message?: string;
  /** The module of a failed transaction. */
  module?: string;
  /** The error parameters, as decoded using the contract abi. Present only when
- the error originated from within a smart contract (e.g. via `revert` in Solidity), and 
- the contract is verified or the revert reason is a plain String.
If this field is present, `message` will include the name of the error, e.g. 'InsufficentBalance'.
Note that users should be cautious when evaluating error data since the
data origin is not tracked and error information can be faked.
 */
  revert_params?: EvmAbiParam[];
}

/**
 * A list of consensus transactions.

 */
export type TransactionListAllOf = {
  transactions: Transaction[];
};

export type ConsensusTxMethod = typeof ConsensusTxMethod[keyof typeof ConsensusTxMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsensusTxMethod = {
  stakingTransfer: 'staking.Transfer',
  stakingAddEscrow: 'staking.AddEscrow',
  stakingReclaimEscrow: 'staking.ReclaimEscrow',
  stakingAmendCommissionSchedule: 'staking.AmendCommissionSchedule',
  stakingAllow: 'staking.Allow',
  stakingWithdraw: 'staking.Withdraw',
  roothashExecutorCommit: 'roothash.ExecutorCommit',
  roothashExecutorProposerTimeout: 'roothash.ExecutorProposerTimeout',
  registryRegisterEntity: 'registry.RegisterEntity',
  registryRegisterNode: 'registry.RegisterNode',
  registryRegisterRuntime: 'registry.RegisterRuntime',
  governanceCastVote: 'governance.CastVote',
  governanceSubmitProposal: 'governance.SubmitProposal',
  beaconPVSSCommit: 'beacon.PVSSCommit',
  beaconPVSSReveal: 'beacon.PVSSReveal',
  beaconVRFProve: 'beacon.VRFProve',
} as const;

/**
 * A consensus transaction.

 */
export interface Transaction {
  /** The block height at which this transaction was executed. */
  block: number;
  /** The method call body. */
  body: string;
  /** Error details of a failed transaction. */
  error?: TxError;
  /** The fee that this transaction's sender committed
to pay to execute it.
 */
  fee: TextBigInt;
  /** The cryptographic hash of this transaction's encoding. */
  hash: string;
  /** 0-based index of this transaction in its block */
  index: number;
  /** The method that was called. */
  method: ConsensusTxMethod;
  /** The nonce used with this transaction, to prevent replay. */
  nonce: number;
  /** The address of who sent this transaction. */
  sender: string;
  /** Whether this transaction successfully executed. */
  success: boolean;
  /** The second-granular consensus time of this tx's block, i.e. roughly when the
[block was proposed](https://github.com/tendermint/tendermint/blob/v0.34.x/spec/core/data_structures.md#header).
 */
  timestamp: string;
}

/**
 * A debonding delegation.

 */
export interface DebondingDelegation {
  /** The amount of tokens delegated in base units. */
  amount: TextBigInt;
  /** The epoch at which the debonding ends. */
  debond_end: number;
  /** The delegator address. */
  delegator: string;
  /** The shares of tokens delegated. */
  shares: TextBigInt;
  /** The delegatee (validator) address. */
  validator: string;
}

/**
 * A list of debonding delegations.

 */
export type DebondingDelegationListAllOf = {
  debonding_delegations: DebondingDelegation[];
};

/**
 * A delegation.

 */
export interface Delegation {
  /** The amount of tokens delegated in base units. */
  amount: TextBigInt;
  /** The delegator address. */
  delegator: string;
  /** The shares of tokens delegated. */
  shares: TextBigInt;
  /** The delegatee (validator) address. */
  validator: string;
}

/**
 * A list of delegations.

 */
export type DelegationListAllOf = {
  delegations: Delegation[];
};

export type DelegationList = List & DelegationListAllOf;

/**
 * A consensus block.

 */
export interface Block {
  /** The block header hash. */
  hash: string;
  /** The block height. */
  height: number;
  /** Number of transactions in the block. */
  num_transactions: number;
  /** The second-granular consensus time. */
  timestamp: string;
}

export type BlockListAllOf = {
  blocks: Block[];
};

export interface Status {
  /** The height of the most recent indexed block. Compare with latest_node_block to measure
how far behind Nexus is from the chain.
 */
  latest_block: number;
  /** The RFC 3339 formatted consensus time of when the most recently indexed block was produced. */
  latest_block_time: string;
  /** The height of the most recently produced block on-chain as seen by Nexus. */
  latest_node_block: number;
  /** The number of milliseconds since Nexus processed the latest block. */
  latest_update_age_ms: number;
}

export interface List {
  /** Whether total_count is clipped for performance reasons. */
  is_total_count_clipped: boolean;
  /** The total number of records that match the query, i.e. the number of records
the query would return with limit=infinity.
 */
  total_count: number;
}

export type TransactionList = List & TransactionListAllOf;

export type DebondingDelegationList = List & DebondingDelegationListAllOf;

/**
 * A list of consensus blocks.

 */
export type BlockList = List & BlockListAllOf;

export type CallFormat = string;

/**
 * A base64-encoded ed25519 public key.
 */
export type Ed25519PubKey = string;

/**
 * An Oasis-style (bech32) address.
 */
export type Address = string;

export type TextBigInt = string;

/**
 * An Oasis-style (bech32) address.
 */
export type StakingAddress = string;

export type Runtime = typeof Runtime[keyof typeof Runtime];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Runtime = {
  emerald: 'emerald',
  sapphire: 'sapphire',
  cipher: 'cipher',
  pontusx: 'pontusx',
} as const;

export type Layer = typeof Layer[keyof typeof Layer];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Layer = {
  emerald: 'emerald',
  sapphire: 'sapphire',
  cipher: 'cipher',
  pontusx: 'pontusx',
  consensus: 'consensus',
} as const;



type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * @summary Returns the status of indexing.
 */
export const getStatus = (
    network: 'mainnet' | 'testnet',
 options?: SecondParameter<typeof getStatusMutator>,signal?: AbortSignal
) => {
      
      
      return getStatusMutator<Status>(
      {url: `/${encodeURIComponent(String(network))}/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetStatusQueryKey = (network: 'mainnet' | 'testnet',) => {
    return [`/${network}/`] as const;
    }

    
export const getGetStatusQueryOptions = <TData = Awaited<ReturnType<typeof getStatus>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet', options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getStatus>>, TError, TData>, request?: SecondParameter<typeof getStatusMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStatusQueryKey(network);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStatus>>> = ({ signal }) => getStatus(network, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStatus>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getStatus>>>
export type GetStatusQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns the status of indexing.
 */
export const useGetStatus = <TData = Awaited<ReturnType<typeof getStatus>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet', options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getStatus>>, TError, TData>, request?: SecondParameter<typeof getStatusMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStatusQueryOptions(network,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a list of consensus blocks, sorted from most to least recent.
 */
export const GetConsensusBlocks = (
    network: 'mainnet' | 'testnet',
    params?: GetConsensusBlocksParams,
 options?: SecondParameter<typeof GetConsensusBlocksMutator>,signal?: AbortSignal
) => {
      
      
      return GetConsensusBlocksMutator<BlockList>(
      {url: `/${encodeURIComponent(String(network))}/consensus/blocks`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetConsensusBlocksQueryKey = (network: 'mainnet' | 'testnet',
    params?: GetConsensusBlocksParams,) => {
    return [`/${network}/consensus/blocks`, ...(params ? [params]: [])] as const;
    }

    
export const getGetConsensusBlocksQueryOptions = <TData = Awaited<ReturnType<typeof GetConsensusBlocks>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    params?: GetConsensusBlocksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusBlocks>>, TError, TData>, request?: SecondParameter<typeof GetConsensusBlocksMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsensusBlocksQueryKey(network,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetConsensusBlocks>>> = ({ signal }) => GetConsensusBlocks(network,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetConsensusBlocks>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConsensusBlocksQueryResult = NonNullable<Awaited<ReturnType<typeof GetConsensusBlocks>>>
export type GetConsensusBlocksQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a list of consensus blocks, sorted from most to least recent.
 */
export const useGetConsensusBlocks = <TData = Awaited<ReturnType<typeof GetConsensusBlocks>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    params?: GetConsensusBlocksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusBlocks>>, TError, TData>, request?: SecondParameter<typeof GetConsensusBlocksMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConsensusBlocksQueryOptions(network,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a consensus block.
 */
export const GetConsensusBlocksHeight = (
    network: 'mainnet' | 'testnet',
    height: number,
 options?: SecondParameter<typeof GetConsensusBlocksHeightMutator>,signal?: AbortSignal
) => {
      
      
      return GetConsensusBlocksHeightMutator<Block>(
      {url: `/${encodeURIComponent(String(network))}/consensus/blocks/${encodeURIComponent(String(height))}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetConsensusBlocksHeightQueryKey = (network: 'mainnet' | 'testnet',
    height: number,) => {
    return [`/${network}/consensus/blocks/${height}`] as const;
    }

    
export const getGetConsensusBlocksHeightQueryOptions = <TData = Awaited<ReturnType<typeof GetConsensusBlocksHeight>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    height: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusBlocksHeight>>, TError, TData>, request?: SecondParameter<typeof GetConsensusBlocksHeightMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsensusBlocksHeightQueryKey(network,height);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetConsensusBlocksHeight>>> = ({ signal }) => GetConsensusBlocksHeight(network,height, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && height), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetConsensusBlocksHeight>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConsensusBlocksHeightQueryResult = NonNullable<Awaited<ReturnType<typeof GetConsensusBlocksHeight>>>
export type GetConsensusBlocksHeightQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a consensus block.
 */
export const useGetConsensusBlocksHeight = <TData = Awaited<ReturnType<typeof GetConsensusBlocksHeight>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    height: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusBlocksHeight>>, TError, TData>, request?: SecondParameter<typeof GetConsensusBlocksHeightMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConsensusBlocksHeightQueryOptions(network,height,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a list of consensus transactions.
 */
export const GetConsensusTransactions = (
    network: 'mainnet' | 'testnet',
    params?: GetConsensusTransactionsParams,
 options?: SecondParameter<typeof GetConsensusTransactionsMutator>,signal?: AbortSignal
) => {
      
      
      return GetConsensusTransactionsMutator<TransactionList>(
      {url: `/${encodeURIComponent(String(network))}/consensus/transactions`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetConsensusTransactionsQueryKey = (network: 'mainnet' | 'testnet',
    params?: GetConsensusTransactionsParams,) => {
    return [`/${network}/consensus/transactions`, ...(params ? [params]: [])] as const;
    }

    
export const getGetConsensusTransactionsQueryOptions = <TData = Awaited<ReturnType<typeof GetConsensusTransactions>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    params?: GetConsensusTransactionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusTransactions>>, TError, TData>, request?: SecondParameter<typeof GetConsensusTransactionsMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsensusTransactionsQueryKey(network,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetConsensusTransactions>>> = ({ signal }) => GetConsensusTransactions(network,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetConsensusTransactions>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConsensusTransactionsQueryResult = NonNullable<Awaited<ReturnType<typeof GetConsensusTransactions>>>
export type GetConsensusTransactionsQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a list of consensus transactions.
 */
export const useGetConsensusTransactions = <TData = Awaited<ReturnType<typeof GetConsensusTransactions>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    params?: GetConsensusTransactionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusTransactions>>, TError, TData>, request?: SecondParameter<typeof GetConsensusTransactionsMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConsensusTransactionsQueryOptions(network,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a consensus transaction.
 */
export const GetConsensusTransactionsTxHash = (
    network: 'mainnet' | 'testnet',
    txHash: string,
 options?: SecondParameter<typeof GetConsensusTransactionsTxHashMutator>,signal?: AbortSignal
) => {
      
      
      return GetConsensusTransactionsTxHashMutator<Transaction>(
      {url: `/${encodeURIComponent(String(network))}/consensus/transactions/${encodeURIComponent(String(txHash))}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetConsensusTransactionsTxHashQueryKey = (network: 'mainnet' | 'testnet',
    txHash: string,) => {
    return [`/${network}/consensus/transactions/${txHash}`] as const;
    }

    
export const getGetConsensusTransactionsTxHashQueryOptions = <TData = Awaited<ReturnType<typeof GetConsensusTransactionsTxHash>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    txHash: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusTransactionsTxHash>>, TError, TData>, request?: SecondParameter<typeof GetConsensusTransactionsTxHashMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsensusTransactionsTxHashQueryKey(network,txHash);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetConsensusTransactionsTxHash>>> = ({ signal }) => GetConsensusTransactionsTxHash(network,txHash, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && txHash), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetConsensusTransactionsTxHash>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConsensusTransactionsTxHashQueryResult = NonNullable<Awaited<ReturnType<typeof GetConsensusTransactionsTxHash>>>
export type GetConsensusTransactionsTxHashQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a consensus transaction.
 */
export const useGetConsensusTransactionsTxHash = <TData = Awaited<ReturnType<typeof GetConsensusTransactionsTxHash>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    txHash: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusTransactionsTxHash>>, TError, TData>, request?: SecondParameter<typeof GetConsensusTransactionsTxHashMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConsensusTransactionsTxHashQueryOptions(network,txHash,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a list of consensus events.
 */
export const GetConsensusEvents = (
    network: 'mainnet' | 'testnet',
    params?: GetConsensusEventsParams,
 options?: SecondParameter<typeof GetConsensusEventsMutator>,signal?: AbortSignal
) => {
      
      
      return GetConsensusEventsMutator<ConsensusEventList>(
      {url: `/${encodeURIComponent(String(network))}/consensus/events`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetConsensusEventsQueryKey = (network: 'mainnet' | 'testnet',
    params?: GetConsensusEventsParams,) => {
    return [`/${network}/consensus/events`, ...(params ? [params]: [])] as const;
    }

    
export const getGetConsensusEventsQueryOptions = <TData = Awaited<ReturnType<typeof GetConsensusEvents>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    params?: GetConsensusEventsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusEvents>>, TError, TData>, request?: SecondParameter<typeof GetConsensusEventsMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsensusEventsQueryKey(network,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetConsensusEvents>>> = ({ signal }) => GetConsensusEvents(network,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetConsensusEvents>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConsensusEventsQueryResult = NonNullable<Awaited<ReturnType<typeof GetConsensusEvents>>>
export type GetConsensusEventsQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a list of consensus events.
 */
export const useGetConsensusEvents = <TData = Awaited<ReturnType<typeof GetConsensusEvents>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    params?: GetConsensusEventsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusEvents>>, TError, TData>, request?: SecondParameter<typeof GetConsensusEventsMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConsensusEventsQueryOptions(network,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a list of entities registered at the consensus layer.
 */
export const GetConsensusEntities = (
    network: 'mainnet' | 'testnet',
    params?: GetConsensusEntitiesParams,
 options?: SecondParameter<typeof GetConsensusEntitiesMutator>,signal?: AbortSignal
) => {
      
      
      return GetConsensusEntitiesMutator<EntityList>(
      {url: `/${encodeURIComponent(String(network))}/consensus/entities`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetConsensusEntitiesQueryKey = (network: 'mainnet' | 'testnet',
    params?: GetConsensusEntitiesParams,) => {
    return [`/${network}/consensus/entities`, ...(params ? [params]: [])] as const;
    }

    
export const getGetConsensusEntitiesQueryOptions = <TData = Awaited<ReturnType<typeof GetConsensusEntities>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    params?: GetConsensusEntitiesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusEntities>>, TError, TData>, request?: SecondParameter<typeof GetConsensusEntitiesMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsensusEntitiesQueryKey(network,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetConsensusEntities>>> = ({ signal }) => GetConsensusEntities(network,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetConsensusEntities>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConsensusEntitiesQueryResult = NonNullable<Awaited<ReturnType<typeof GetConsensusEntities>>>
export type GetConsensusEntitiesQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a list of entities registered at the consensus layer.
 */
export const useGetConsensusEntities = <TData = Awaited<ReturnType<typeof GetConsensusEntities>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    params?: GetConsensusEntitiesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusEntities>>, TError, TData>, request?: SecondParameter<typeof GetConsensusEntitiesMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConsensusEntitiesQueryOptions(network,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns an entity registered at the consensus layer.
 */
export const GetConsensusEntitiesEntityId = (
    network: 'mainnet' | 'testnet',
    entityId: Ed25519PubKey,
 options?: SecondParameter<typeof GetConsensusEntitiesEntityIdMutator>,signal?: AbortSignal
) => {
      
      
      return GetConsensusEntitiesEntityIdMutator<Entity>(
      {url: `/${encodeURIComponent(String(network))}/consensus/entities/${encodeURIComponent(String(entityId))}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetConsensusEntitiesEntityIdQueryKey = (network: 'mainnet' | 'testnet',
    entityId: Ed25519PubKey,) => {
    return [`/${network}/consensus/entities/${entityId}`] as const;
    }

    
export const getGetConsensusEntitiesEntityIdQueryOptions = <TData = Awaited<ReturnType<typeof GetConsensusEntitiesEntityId>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    entityId: Ed25519PubKey, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusEntitiesEntityId>>, TError, TData>, request?: SecondParameter<typeof GetConsensusEntitiesEntityIdMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsensusEntitiesEntityIdQueryKey(network,entityId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetConsensusEntitiesEntityId>>> = ({ signal }) => GetConsensusEntitiesEntityId(network,entityId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && entityId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetConsensusEntitiesEntityId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConsensusEntitiesEntityIdQueryResult = NonNullable<Awaited<ReturnType<typeof GetConsensusEntitiesEntityId>>>
export type GetConsensusEntitiesEntityIdQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns an entity registered at the consensus layer.
 */
export const useGetConsensusEntitiesEntityId = <TData = Awaited<ReturnType<typeof GetConsensusEntitiesEntityId>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    entityId: Ed25519PubKey, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusEntitiesEntityId>>, TError, TData>, request?: SecondParameter<typeof GetConsensusEntitiesEntityIdMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConsensusEntitiesEntityIdQueryOptions(network,entityId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a list of nodes registered at the consensus layer.
 */
export const GetConsensusEntitiesEntityIdNodes = (
    network: 'mainnet' | 'testnet',
    entityId: Ed25519PubKey,
    params?: GetConsensusEntitiesEntityIdNodesParams,
 options?: SecondParameter<typeof GetConsensusEntitiesEntityIdNodesMutator>,signal?: AbortSignal
) => {
      
      
      return GetConsensusEntitiesEntityIdNodesMutator<NodeList>(
      {url: `/${encodeURIComponent(String(network))}/consensus/entities/${encodeURIComponent(String(entityId))}/nodes`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetConsensusEntitiesEntityIdNodesQueryKey = (network: 'mainnet' | 'testnet',
    entityId: Ed25519PubKey,
    params?: GetConsensusEntitiesEntityIdNodesParams,) => {
    return [`/${network}/consensus/entities/${entityId}/nodes`, ...(params ? [params]: [])] as const;
    }

    
export const getGetConsensusEntitiesEntityIdNodesQueryOptions = <TData = Awaited<ReturnType<typeof GetConsensusEntitiesEntityIdNodes>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    entityId: Ed25519PubKey,
    params?: GetConsensusEntitiesEntityIdNodesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusEntitiesEntityIdNodes>>, TError, TData>, request?: SecondParameter<typeof GetConsensusEntitiesEntityIdNodesMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsensusEntitiesEntityIdNodesQueryKey(network,entityId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetConsensusEntitiesEntityIdNodes>>> = ({ signal }) => GetConsensusEntitiesEntityIdNodes(network,entityId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && entityId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetConsensusEntitiesEntityIdNodes>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConsensusEntitiesEntityIdNodesQueryResult = NonNullable<Awaited<ReturnType<typeof GetConsensusEntitiesEntityIdNodes>>>
export type GetConsensusEntitiesEntityIdNodesQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a list of nodes registered at the consensus layer.
 */
export const useGetConsensusEntitiesEntityIdNodes = <TData = Awaited<ReturnType<typeof GetConsensusEntitiesEntityIdNodes>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    entityId: Ed25519PubKey,
    params?: GetConsensusEntitiesEntityIdNodesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusEntitiesEntityIdNodes>>, TError, TData>, request?: SecondParameter<typeof GetConsensusEntitiesEntityIdNodesMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConsensusEntitiesEntityIdNodesQueryOptions(network,entityId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a node registered at the consensus layer.
 */
export const GetConsensusEntitiesEntityIdNodesNodeId = (
    network: 'mainnet' | 'testnet',
    entityId: Ed25519PubKey,
    nodeId: Ed25519PubKey,
 options?: SecondParameter<typeof GetConsensusEntitiesEntityIdNodesNodeIdMutator>,signal?: AbortSignal
) => {
      
      
      return GetConsensusEntitiesEntityIdNodesNodeIdMutator<Node>(
      {url: `/${encodeURIComponent(String(network))}/consensus/entities/${encodeURIComponent(String(entityId))}/nodes/${encodeURIComponent(String(nodeId))}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetConsensusEntitiesEntityIdNodesNodeIdQueryKey = (network: 'mainnet' | 'testnet',
    entityId: Ed25519PubKey,
    nodeId: Ed25519PubKey,) => {
    return [`/${network}/consensus/entities/${entityId}/nodes/${nodeId}`] as const;
    }

    
export const getGetConsensusEntitiesEntityIdNodesNodeIdQueryOptions = <TData = Awaited<ReturnType<typeof GetConsensusEntitiesEntityIdNodesNodeId>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    entityId: Ed25519PubKey,
    nodeId: Ed25519PubKey, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusEntitiesEntityIdNodesNodeId>>, TError, TData>, request?: SecondParameter<typeof GetConsensusEntitiesEntityIdNodesNodeIdMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsensusEntitiesEntityIdNodesNodeIdQueryKey(network,entityId,nodeId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetConsensusEntitiesEntityIdNodesNodeId>>> = ({ signal }) => GetConsensusEntitiesEntityIdNodesNodeId(network,entityId,nodeId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && entityId && nodeId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetConsensusEntitiesEntityIdNodesNodeId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConsensusEntitiesEntityIdNodesNodeIdQueryResult = NonNullable<Awaited<ReturnType<typeof GetConsensusEntitiesEntityIdNodesNodeId>>>
export type GetConsensusEntitiesEntityIdNodesNodeIdQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a node registered at the consensus layer.
 */
export const useGetConsensusEntitiesEntityIdNodesNodeId = <TData = Awaited<ReturnType<typeof GetConsensusEntitiesEntityIdNodesNodeId>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    entityId: Ed25519PubKey,
    nodeId: Ed25519PubKey, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusEntitiesEntityIdNodesNodeId>>, TError, TData>, request?: SecondParameter<typeof GetConsensusEntitiesEntityIdNodesNodeIdMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConsensusEntitiesEntityIdNodesNodeIdQueryOptions(network,entityId,nodeId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a list of validators registered at the consensus layer.
 */
export const GetConsensusValidators = (
    network: 'mainnet' | 'testnet',
    params?: GetConsensusValidatorsParams,
 options?: SecondParameter<typeof GetConsensusValidatorsMutator>,signal?: AbortSignal
) => {
      
      
      return GetConsensusValidatorsMutator<ValidatorList>(
      {url: `/${encodeURIComponent(String(network))}/consensus/validators`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetConsensusValidatorsQueryKey = (network: 'mainnet' | 'testnet',
    params?: GetConsensusValidatorsParams,) => {
    return [`/${network}/consensus/validators`, ...(params ? [params]: [])] as const;
    }

    
export const getGetConsensusValidatorsQueryOptions = <TData = Awaited<ReturnType<typeof GetConsensusValidators>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    params?: GetConsensusValidatorsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusValidators>>, TError, TData>, request?: SecondParameter<typeof GetConsensusValidatorsMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsensusValidatorsQueryKey(network,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetConsensusValidators>>> = ({ signal }) => GetConsensusValidators(network,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetConsensusValidators>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConsensusValidatorsQueryResult = NonNullable<Awaited<ReturnType<typeof GetConsensusValidators>>>
export type GetConsensusValidatorsQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a list of validators registered at the consensus layer.
 */
export const useGetConsensusValidators = <TData = Awaited<ReturnType<typeof GetConsensusValidators>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    params?: GetConsensusValidatorsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusValidators>>, TError, TData>, request?: SecondParameter<typeof GetConsensusValidatorsMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConsensusValidatorsQueryOptions(network,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a validator registered at the consensus layer.
 */
export const GetConsensusValidatorsEntityId = (
    network: 'mainnet' | 'testnet',
    entityId: Ed25519PubKey,
 options?: SecondParameter<typeof GetConsensusValidatorsEntityIdMutator>,signal?: AbortSignal
) => {
      
      
      return GetConsensusValidatorsEntityIdMutator<Validator>(
      {url: `/${encodeURIComponent(String(network))}/consensus/validators/${encodeURIComponent(String(entityId))}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetConsensusValidatorsEntityIdQueryKey = (network: 'mainnet' | 'testnet',
    entityId: Ed25519PubKey,) => {
    return [`/${network}/consensus/validators/${entityId}`] as const;
    }

    
export const getGetConsensusValidatorsEntityIdQueryOptions = <TData = Awaited<ReturnType<typeof GetConsensusValidatorsEntityId>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    entityId: Ed25519PubKey, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusValidatorsEntityId>>, TError, TData>, request?: SecondParameter<typeof GetConsensusValidatorsEntityIdMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsensusValidatorsEntityIdQueryKey(network,entityId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetConsensusValidatorsEntityId>>> = ({ signal }) => GetConsensusValidatorsEntityId(network,entityId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && entityId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetConsensusValidatorsEntityId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConsensusValidatorsEntityIdQueryResult = NonNullable<Awaited<ReturnType<typeof GetConsensusValidatorsEntityId>>>
export type GetConsensusValidatorsEntityIdQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a validator registered at the consensus layer.
 */
export const useGetConsensusValidatorsEntityId = <TData = Awaited<ReturnType<typeof GetConsensusValidatorsEntityId>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    entityId: Ed25519PubKey, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusValidatorsEntityId>>, TError, TData>, request?: SecondParameter<typeof GetConsensusValidatorsEntityIdMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConsensusValidatorsEntityIdQueryOptions(network,entityId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a list of consensus layer accounts.
 */
export const GetConsensusAccounts = (
    network: 'mainnet' | 'testnet',
    params?: GetConsensusAccountsParams,
 options?: SecondParameter<typeof GetConsensusAccountsMutator>,signal?: AbortSignal
) => {
      
      
      return GetConsensusAccountsMutator<AccountList>(
      {url: `/${encodeURIComponent(String(network))}/consensus/accounts`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetConsensusAccountsQueryKey = (network: 'mainnet' | 'testnet',
    params?: GetConsensusAccountsParams,) => {
    return [`/${network}/consensus/accounts`, ...(params ? [params]: [])] as const;
    }

    
export const getGetConsensusAccountsQueryOptions = <TData = Awaited<ReturnType<typeof GetConsensusAccounts>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    params?: GetConsensusAccountsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusAccounts>>, TError, TData>, request?: SecondParameter<typeof GetConsensusAccountsMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsensusAccountsQueryKey(network,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetConsensusAccounts>>> = ({ signal }) => GetConsensusAccounts(network,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetConsensusAccounts>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConsensusAccountsQueryResult = NonNullable<Awaited<ReturnType<typeof GetConsensusAccounts>>>
export type GetConsensusAccountsQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a list of consensus layer accounts.
 */
export const useGetConsensusAccounts = <TData = Awaited<ReturnType<typeof GetConsensusAccounts>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    params?: GetConsensusAccountsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusAccounts>>, TError, TData>, request?: SecondParameter<typeof GetConsensusAccountsMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConsensusAccountsQueryOptions(network,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a consensus layer account.
 */
export const GetConsensusAccountsAddress = (
    network: 'mainnet' | 'testnet',
    address: StakingAddress,
 options?: SecondParameter<typeof GetConsensusAccountsAddressMutator>,signal?: AbortSignal
) => {
      
      
      return GetConsensusAccountsAddressMutator<Account>(
      {url: `/${encodeURIComponent(String(network))}/consensus/accounts/${encodeURIComponent(String(address))}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetConsensusAccountsAddressQueryKey = (network: 'mainnet' | 'testnet',
    address: StakingAddress,) => {
    return [`/${network}/consensus/accounts/${address}`] as const;
    }

    
export const getGetConsensusAccountsAddressQueryOptions = <TData = Awaited<ReturnType<typeof GetConsensusAccountsAddress>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    address: StakingAddress, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusAccountsAddress>>, TError, TData>, request?: SecondParameter<typeof GetConsensusAccountsAddressMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsensusAccountsAddressQueryKey(network,address);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetConsensusAccountsAddress>>> = ({ signal }) => GetConsensusAccountsAddress(network,address, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && address), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetConsensusAccountsAddress>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConsensusAccountsAddressQueryResult = NonNullable<Awaited<ReturnType<typeof GetConsensusAccountsAddress>>>
export type GetConsensusAccountsAddressQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a consensus layer account.
 */
export const useGetConsensusAccountsAddress = <TData = Awaited<ReturnType<typeof GetConsensusAccountsAddress>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    address: StakingAddress, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusAccountsAddress>>, TError, TData>, request?: SecondParameter<typeof GetConsensusAccountsAddressMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConsensusAccountsAddressQueryOptions(network,address,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns an account's delegations.
 */
export const GetConsensusAccountsAddressDelegations = (
    network: 'mainnet' | 'testnet',
    address: StakingAddress,
    params?: GetConsensusAccountsAddressDelegationsParams,
 options?: SecondParameter<typeof GetConsensusAccountsAddressDelegationsMutator>,signal?: AbortSignal
) => {
      
      
      return GetConsensusAccountsAddressDelegationsMutator<DelegationList>(
      {url: `/${encodeURIComponent(String(network))}/consensus/accounts/${encodeURIComponent(String(address))}/delegations`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetConsensusAccountsAddressDelegationsQueryKey = (network: 'mainnet' | 'testnet',
    address: StakingAddress,
    params?: GetConsensusAccountsAddressDelegationsParams,) => {
    return [`/${network}/consensus/accounts/${address}/delegations`, ...(params ? [params]: [])] as const;
    }

    
export const getGetConsensusAccountsAddressDelegationsQueryOptions = <TData = Awaited<ReturnType<typeof GetConsensusAccountsAddressDelegations>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    address: StakingAddress,
    params?: GetConsensusAccountsAddressDelegationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusAccountsAddressDelegations>>, TError, TData>, request?: SecondParameter<typeof GetConsensusAccountsAddressDelegationsMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsensusAccountsAddressDelegationsQueryKey(network,address,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetConsensusAccountsAddressDelegations>>> = ({ signal }) => GetConsensusAccountsAddressDelegations(network,address,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && address), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetConsensusAccountsAddressDelegations>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConsensusAccountsAddressDelegationsQueryResult = NonNullable<Awaited<ReturnType<typeof GetConsensusAccountsAddressDelegations>>>
export type GetConsensusAccountsAddressDelegationsQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns an account's delegations.
 */
export const useGetConsensusAccountsAddressDelegations = <TData = Awaited<ReturnType<typeof GetConsensusAccountsAddressDelegations>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    address: StakingAddress,
    params?: GetConsensusAccountsAddressDelegationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusAccountsAddressDelegations>>, TError, TData>, request?: SecondParameter<typeof GetConsensusAccountsAddressDelegationsMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConsensusAccountsAddressDelegationsQueryOptions(network,address,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a list of delegations to an account.
 */
export const GetConsensusAccountsAddressDelegationsTo = (
    network: 'mainnet' | 'testnet',
    address: StakingAddress,
    params?: GetConsensusAccountsAddressDelegationsToParams,
 options?: SecondParameter<typeof GetConsensusAccountsAddressDelegationsToMutator>,signal?: AbortSignal
) => {
      
      
      return GetConsensusAccountsAddressDelegationsToMutator<DelegationList>(
      {url: `/${encodeURIComponent(String(network))}/consensus/accounts/${encodeURIComponent(String(address))}/delegations_to`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetConsensusAccountsAddressDelegationsToQueryKey = (network: 'mainnet' | 'testnet',
    address: StakingAddress,
    params?: GetConsensusAccountsAddressDelegationsToParams,) => {
    return [`/${network}/consensus/accounts/${address}/delegations_to`, ...(params ? [params]: [])] as const;
    }

    
export const getGetConsensusAccountsAddressDelegationsToQueryOptions = <TData = Awaited<ReturnType<typeof GetConsensusAccountsAddressDelegationsTo>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    address: StakingAddress,
    params?: GetConsensusAccountsAddressDelegationsToParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusAccountsAddressDelegationsTo>>, TError, TData>, request?: SecondParameter<typeof GetConsensusAccountsAddressDelegationsToMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsensusAccountsAddressDelegationsToQueryKey(network,address,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetConsensusAccountsAddressDelegationsTo>>> = ({ signal }) => GetConsensusAccountsAddressDelegationsTo(network,address,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && address), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetConsensusAccountsAddressDelegationsTo>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConsensusAccountsAddressDelegationsToQueryResult = NonNullable<Awaited<ReturnType<typeof GetConsensusAccountsAddressDelegationsTo>>>
export type GetConsensusAccountsAddressDelegationsToQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a list of delegations to an account.
 */
export const useGetConsensusAccountsAddressDelegationsTo = <TData = Awaited<ReturnType<typeof GetConsensusAccountsAddressDelegationsTo>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    address: StakingAddress,
    params?: GetConsensusAccountsAddressDelegationsToParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusAccountsAddressDelegationsTo>>, TError, TData>, request?: SecondParameter<typeof GetConsensusAccountsAddressDelegationsToMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConsensusAccountsAddressDelegationsToQueryOptions(network,address,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns an account's debonding delegations.
 */
export const GetConsensusAccountsAddressDebondingDelegations = (
    network: 'mainnet' | 'testnet',
    address: StakingAddress,
    params?: GetConsensusAccountsAddressDebondingDelegationsParams,
 options?: SecondParameter<typeof GetConsensusAccountsAddressDebondingDelegationsMutator>,signal?: AbortSignal
) => {
      
      
      return GetConsensusAccountsAddressDebondingDelegationsMutator<DebondingDelegationList>(
      {url: `/${encodeURIComponent(String(network))}/consensus/accounts/${encodeURIComponent(String(address))}/debonding_delegations`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetConsensusAccountsAddressDebondingDelegationsQueryKey = (network: 'mainnet' | 'testnet',
    address: StakingAddress,
    params?: GetConsensusAccountsAddressDebondingDelegationsParams,) => {
    return [`/${network}/consensus/accounts/${address}/debonding_delegations`, ...(params ? [params]: [])] as const;
    }

    
export const getGetConsensusAccountsAddressDebondingDelegationsQueryOptions = <TData = Awaited<ReturnType<typeof GetConsensusAccountsAddressDebondingDelegations>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    address: StakingAddress,
    params?: GetConsensusAccountsAddressDebondingDelegationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusAccountsAddressDebondingDelegations>>, TError, TData>, request?: SecondParameter<typeof GetConsensusAccountsAddressDebondingDelegationsMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsensusAccountsAddressDebondingDelegationsQueryKey(network,address,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetConsensusAccountsAddressDebondingDelegations>>> = ({ signal }) => GetConsensusAccountsAddressDebondingDelegations(network,address,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && address), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetConsensusAccountsAddressDebondingDelegations>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConsensusAccountsAddressDebondingDelegationsQueryResult = NonNullable<Awaited<ReturnType<typeof GetConsensusAccountsAddressDebondingDelegations>>>
export type GetConsensusAccountsAddressDebondingDelegationsQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns an account's debonding delegations.
 */
export const useGetConsensusAccountsAddressDebondingDelegations = <TData = Awaited<ReturnType<typeof GetConsensusAccountsAddressDebondingDelegations>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    address: StakingAddress,
    params?: GetConsensusAccountsAddressDebondingDelegationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusAccountsAddressDebondingDelegations>>, TError, TData>, request?: SecondParameter<typeof GetConsensusAccountsAddressDebondingDelegationsMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConsensusAccountsAddressDebondingDelegationsQueryOptions(network,address,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a list of debonding delegations to an account.
 */
export const GetConsensusAccountsAddressDebondingDelegationsTo = (
    network: 'mainnet' | 'testnet',
    address: StakingAddress,
    params?: GetConsensusAccountsAddressDebondingDelegationsToParams,
 options?: SecondParameter<typeof GetConsensusAccountsAddressDebondingDelegationsToMutator>,signal?: AbortSignal
) => {
      
      
      return GetConsensusAccountsAddressDebondingDelegationsToMutator<DebondingDelegationList>(
      {url: `/${encodeURIComponent(String(network))}/consensus/accounts/${encodeURIComponent(String(address))}/debonding_delegations_to`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetConsensusAccountsAddressDebondingDelegationsToQueryKey = (network: 'mainnet' | 'testnet',
    address: StakingAddress,
    params?: GetConsensusAccountsAddressDebondingDelegationsToParams,) => {
    return [`/${network}/consensus/accounts/${address}/debonding_delegations_to`, ...(params ? [params]: [])] as const;
    }

    
export const getGetConsensusAccountsAddressDebondingDelegationsToQueryOptions = <TData = Awaited<ReturnType<typeof GetConsensusAccountsAddressDebondingDelegationsTo>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    address: StakingAddress,
    params?: GetConsensusAccountsAddressDebondingDelegationsToParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusAccountsAddressDebondingDelegationsTo>>, TError, TData>, request?: SecondParameter<typeof GetConsensusAccountsAddressDebondingDelegationsToMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsensusAccountsAddressDebondingDelegationsToQueryKey(network,address,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetConsensusAccountsAddressDebondingDelegationsTo>>> = ({ signal }) => GetConsensusAccountsAddressDebondingDelegationsTo(network,address,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && address), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetConsensusAccountsAddressDebondingDelegationsTo>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConsensusAccountsAddressDebondingDelegationsToQueryResult = NonNullable<Awaited<ReturnType<typeof GetConsensusAccountsAddressDebondingDelegationsTo>>>
export type GetConsensusAccountsAddressDebondingDelegationsToQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a list of debonding delegations to an account.
 */
export const useGetConsensusAccountsAddressDebondingDelegationsTo = <TData = Awaited<ReturnType<typeof GetConsensusAccountsAddressDebondingDelegationsTo>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    address: StakingAddress,
    params?: GetConsensusAccountsAddressDebondingDelegationsToParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusAccountsAddressDebondingDelegationsTo>>, TError, TData>, request?: SecondParameter<typeof GetConsensusAccountsAddressDebondingDelegationsToMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConsensusAccountsAddressDebondingDelegationsToQueryOptions(network,address,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a list of consensus epochs.
 */
export const GetConsensusEpochs = (
    network: 'mainnet' | 'testnet',
    params?: GetConsensusEpochsParams,
 options?: SecondParameter<typeof GetConsensusEpochsMutator>,signal?: AbortSignal
) => {
      
      
      return GetConsensusEpochsMutator<EpochList>(
      {url: `/${encodeURIComponent(String(network))}/consensus/epochs`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetConsensusEpochsQueryKey = (network: 'mainnet' | 'testnet',
    params?: GetConsensusEpochsParams,) => {
    return [`/${network}/consensus/epochs`, ...(params ? [params]: [])] as const;
    }

    
export const getGetConsensusEpochsQueryOptions = <TData = Awaited<ReturnType<typeof GetConsensusEpochs>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    params?: GetConsensusEpochsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusEpochs>>, TError, TData>, request?: SecondParameter<typeof GetConsensusEpochsMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsensusEpochsQueryKey(network,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetConsensusEpochs>>> = ({ signal }) => GetConsensusEpochs(network,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetConsensusEpochs>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConsensusEpochsQueryResult = NonNullable<Awaited<ReturnType<typeof GetConsensusEpochs>>>
export type GetConsensusEpochsQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a list of consensus epochs.
 */
export const useGetConsensusEpochs = <TData = Awaited<ReturnType<typeof GetConsensusEpochs>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    params?: GetConsensusEpochsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusEpochs>>, TError, TData>, request?: SecondParameter<typeof GetConsensusEpochsMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConsensusEpochsQueryOptions(network,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a consensus epoch.
 */
export const GetConsensusEpochsEpoch = (
    network: 'mainnet' | 'testnet',
    epoch: number,
 options?: SecondParameter<typeof GetConsensusEpochsEpochMutator>,signal?: AbortSignal
) => {
      
      
      return GetConsensusEpochsEpochMutator<Epoch>(
      {url: `/${encodeURIComponent(String(network))}/consensus/epochs/${encodeURIComponent(String(epoch))}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetConsensusEpochsEpochQueryKey = (network: 'mainnet' | 'testnet',
    epoch: number,) => {
    return [`/${network}/consensus/epochs/${epoch}`] as const;
    }

    
export const getGetConsensusEpochsEpochQueryOptions = <TData = Awaited<ReturnType<typeof GetConsensusEpochsEpoch>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    epoch: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusEpochsEpoch>>, TError, TData>, request?: SecondParameter<typeof GetConsensusEpochsEpochMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsensusEpochsEpochQueryKey(network,epoch);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetConsensusEpochsEpoch>>> = ({ signal }) => GetConsensusEpochsEpoch(network,epoch, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && epoch), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetConsensusEpochsEpoch>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConsensusEpochsEpochQueryResult = NonNullable<Awaited<ReturnType<typeof GetConsensusEpochsEpoch>>>
export type GetConsensusEpochsEpochQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a consensus epoch.
 */
export const useGetConsensusEpochsEpoch = <TData = Awaited<ReturnType<typeof GetConsensusEpochsEpoch>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    epoch: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusEpochsEpoch>>, TError, TData>, request?: SecondParameter<typeof GetConsensusEpochsEpochMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConsensusEpochsEpochQueryOptions(network,epoch,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a list of governance proposals.
 */
export const GetConsensusProposals = (
    network: 'mainnet' | 'testnet',
    params?: GetConsensusProposalsParams,
 options?: SecondParameter<typeof GetConsensusProposalsMutator>,signal?: AbortSignal
) => {
      
      
      return GetConsensusProposalsMutator<ProposalList>(
      {url: `/${encodeURIComponent(String(network))}/consensus/proposals`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetConsensusProposalsQueryKey = (network: 'mainnet' | 'testnet',
    params?: GetConsensusProposalsParams,) => {
    return [`/${network}/consensus/proposals`, ...(params ? [params]: [])] as const;
    }

    
export const getGetConsensusProposalsQueryOptions = <TData = Awaited<ReturnType<typeof GetConsensusProposals>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    params?: GetConsensusProposalsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusProposals>>, TError, TData>, request?: SecondParameter<typeof GetConsensusProposalsMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsensusProposalsQueryKey(network,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetConsensusProposals>>> = ({ signal }) => GetConsensusProposals(network,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetConsensusProposals>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConsensusProposalsQueryResult = NonNullable<Awaited<ReturnType<typeof GetConsensusProposals>>>
export type GetConsensusProposalsQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a list of governance proposals.
 */
export const useGetConsensusProposals = <TData = Awaited<ReturnType<typeof GetConsensusProposals>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    params?: GetConsensusProposalsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusProposals>>, TError, TData>, request?: SecondParameter<typeof GetConsensusProposalsMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConsensusProposalsQueryOptions(network,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a governance proposal.
 */
export const GetConsensusProposalsProposalId = (
    network: 'mainnet' | 'testnet',
    proposalId: number,
 options?: SecondParameter<typeof GetConsensusProposalsProposalIdMutator>,signal?: AbortSignal
) => {
      
      
      return GetConsensusProposalsProposalIdMutator<Proposal>(
      {url: `/${encodeURIComponent(String(network))}/consensus/proposals/${encodeURIComponent(String(proposalId))}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetConsensusProposalsProposalIdQueryKey = (network: 'mainnet' | 'testnet',
    proposalId: number,) => {
    return [`/${network}/consensus/proposals/${proposalId}`] as const;
    }

    
export const getGetConsensusProposalsProposalIdQueryOptions = <TData = Awaited<ReturnType<typeof GetConsensusProposalsProposalId>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    proposalId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusProposalsProposalId>>, TError, TData>, request?: SecondParameter<typeof GetConsensusProposalsProposalIdMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsensusProposalsProposalIdQueryKey(network,proposalId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetConsensusProposalsProposalId>>> = ({ signal }) => GetConsensusProposalsProposalId(network,proposalId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && proposalId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetConsensusProposalsProposalId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConsensusProposalsProposalIdQueryResult = NonNullable<Awaited<ReturnType<typeof GetConsensusProposalsProposalId>>>
export type GetConsensusProposalsProposalIdQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a governance proposal.
 */
export const useGetConsensusProposalsProposalId = <TData = Awaited<ReturnType<typeof GetConsensusProposalsProposalId>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    proposalId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusProposalsProposalId>>, TError, TData>, request?: SecondParameter<typeof GetConsensusProposalsProposalIdMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConsensusProposalsProposalIdQueryOptions(network,proposalId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a list of votes for a governance proposal.
 */
export const GetConsensusProposalsProposalIdVotes = (
    network: 'mainnet' | 'testnet',
    proposalId: number,
    params?: GetConsensusProposalsProposalIdVotesParams,
 options?: SecondParameter<typeof GetConsensusProposalsProposalIdVotesMutator>,signal?: AbortSignal
) => {
      
      
      return GetConsensusProposalsProposalIdVotesMutator<ProposalVotes>(
      {url: `/${encodeURIComponent(String(network))}/consensus/proposals/${encodeURIComponent(String(proposalId))}/votes`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetConsensusProposalsProposalIdVotesQueryKey = (network: 'mainnet' | 'testnet',
    proposalId: number,
    params?: GetConsensusProposalsProposalIdVotesParams,) => {
    return [`/${network}/consensus/proposals/${proposalId}/votes`, ...(params ? [params]: [])] as const;
    }

    
export const getGetConsensusProposalsProposalIdVotesQueryOptions = <TData = Awaited<ReturnType<typeof GetConsensusProposalsProposalIdVotes>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    proposalId: number,
    params?: GetConsensusProposalsProposalIdVotesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusProposalsProposalIdVotes>>, TError, TData>, request?: SecondParameter<typeof GetConsensusProposalsProposalIdVotesMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsensusProposalsProposalIdVotesQueryKey(network,proposalId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetConsensusProposalsProposalIdVotes>>> = ({ signal }) => GetConsensusProposalsProposalIdVotes(network,proposalId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && proposalId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetConsensusProposalsProposalIdVotes>>, TError, TData> & { queryKey: QueryKey }
}

export type GetConsensusProposalsProposalIdVotesQueryResult = NonNullable<Awaited<ReturnType<typeof GetConsensusProposalsProposalIdVotes>>>
export type GetConsensusProposalsProposalIdVotesQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a list of votes for a governance proposal.
 */
export const useGetConsensusProposalsProposalIdVotes = <TData = Awaited<ReturnType<typeof GetConsensusProposalsProposalIdVotes>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    proposalId: number,
    params?: GetConsensusProposalsProposalIdVotesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetConsensusProposalsProposalIdVotes>>, TError, TData>, request?: SecondParameter<typeof GetConsensusProposalsProposalIdVotesMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConsensusProposalsProposalIdVotesQueryOptions(network,proposalId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a list of Runtime blocks.
 */
export const GetRuntimeBlocks = (
    network: 'mainnet' | 'testnet',
    runtime: Runtime,
    params?: GetRuntimeBlocksParams,
 options?: SecondParameter<typeof GetRuntimeBlocksMutator>,signal?: AbortSignal
) => {
      
      
      return GetRuntimeBlocksMutator<RuntimeBlockList>(
      {url: `/${encodeURIComponent(String(network))}/${encodeURIComponent(String(runtime))}/blocks`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetRuntimeBlocksQueryKey = (network: 'mainnet' | 'testnet',
    runtime: Runtime,
    params?: GetRuntimeBlocksParams,) => {
    return [`/${network}/${runtime}/blocks`, ...(params ? [params]: [])] as const;
    }

    
export const getGetRuntimeBlocksQueryOptions = <TData = Awaited<ReturnType<typeof GetRuntimeBlocks>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    runtime: Runtime,
    params?: GetRuntimeBlocksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeBlocks>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeBlocksMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRuntimeBlocksQueryKey(network,runtime,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetRuntimeBlocks>>> = ({ signal }) => GetRuntimeBlocks(network,runtime,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && runtime), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeBlocks>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRuntimeBlocksQueryResult = NonNullable<Awaited<ReturnType<typeof GetRuntimeBlocks>>>
export type GetRuntimeBlocksQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a list of Runtime blocks.
 */
export const useGetRuntimeBlocks = <TData = Awaited<ReturnType<typeof GetRuntimeBlocks>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    runtime: Runtime,
    params?: GetRuntimeBlocksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeBlocks>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeBlocksMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRuntimeBlocksQueryOptions(network,runtime,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a list of Runtime transactions.
 */
export const GetRuntimeTransactions = (
    network: 'mainnet' | 'testnet',
    runtime: Runtime,
    params?: GetRuntimeTransactionsParams,
 options?: SecondParameter<typeof GetRuntimeTransactionsMutator>,signal?: AbortSignal
) => {
      
      
      return GetRuntimeTransactionsMutator<RuntimeTransactionList>(
      {url: `/${encodeURIComponent(String(network))}/${encodeURIComponent(String(runtime))}/transactions`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetRuntimeTransactionsQueryKey = (network: 'mainnet' | 'testnet',
    runtime: Runtime,
    params?: GetRuntimeTransactionsParams,) => {
    return [`/${network}/${runtime}/transactions`, ...(params ? [params]: [])] as const;
    }

    
export const getGetRuntimeTransactionsQueryOptions = <TData = Awaited<ReturnType<typeof GetRuntimeTransactions>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    runtime: Runtime,
    params?: GetRuntimeTransactionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeTransactions>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeTransactionsMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRuntimeTransactionsQueryKey(network,runtime,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetRuntimeTransactions>>> = ({ signal }) => GetRuntimeTransactions(network,runtime,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && runtime), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeTransactions>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRuntimeTransactionsQueryResult = NonNullable<Awaited<ReturnType<typeof GetRuntimeTransactions>>>
export type GetRuntimeTransactionsQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a list of Runtime transactions.
 */
export const useGetRuntimeTransactions = <TData = Awaited<ReturnType<typeof GetRuntimeTransactions>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    runtime: Runtime,
    params?: GetRuntimeTransactionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeTransactions>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeTransactionsMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRuntimeTransactionsQueryOptions(network,runtime,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns runtime transactions with the given transaction hash.
 */
export const GetRuntimeTransactionsTxHash = (
    network: 'mainnet' | 'testnet',
    runtime: Runtime,
    txHash: string,
 options?: SecondParameter<typeof GetRuntimeTransactionsTxHashMutator>,signal?: AbortSignal
) => {
      
      
      return GetRuntimeTransactionsTxHashMutator<RuntimeTransactionList>(
      {url: `/${encodeURIComponent(String(network))}/${encodeURIComponent(String(runtime))}/transactions/${encodeURIComponent(String(txHash))}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRuntimeTransactionsTxHashQueryKey = (network: 'mainnet' | 'testnet',
    runtime: Runtime,
    txHash: string,) => {
    return [`/${network}/${runtime}/transactions/${txHash}`] as const;
    }

    
export const getGetRuntimeTransactionsTxHashQueryOptions = <TData = Awaited<ReturnType<typeof GetRuntimeTransactionsTxHash>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    runtime: Runtime,
    txHash: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeTransactionsTxHash>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeTransactionsTxHashMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRuntimeTransactionsTxHashQueryKey(network,runtime,txHash);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetRuntimeTransactionsTxHash>>> = ({ signal }) => GetRuntimeTransactionsTxHash(network,runtime,txHash, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && runtime && txHash), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeTransactionsTxHash>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRuntimeTransactionsTxHashQueryResult = NonNullable<Awaited<ReturnType<typeof GetRuntimeTransactionsTxHash>>>
export type GetRuntimeTransactionsTxHashQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns runtime transactions with the given transaction hash.
 */
export const useGetRuntimeTransactionsTxHash = <TData = Awaited<ReturnType<typeof GetRuntimeTransactionsTxHash>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    runtime: Runtime,
    txHash: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeTransactionsTxHash>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeTransactionsTxHashMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRuntimeTransactionsTxHashQueryOptions(network,runtime,txHash,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a list of runtime events.
 */
export const GetRuntimeEvents = (
    network: 'mainnet' | 'testnet',
    runtime: Runtime,
    params?: GetRuntimeEventsParams,
 options?: SecondParameter<typeof GetRuntimeEventsMutator>,signal?: AbortSignal
) => {
      
      
      return GetRuntimeEventsMutator<RuntimeEventList>(
      {url: `/${encodeURIComponent(String(network))}/${encodeURIComponent(String(runtime))}/events`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetRuntimeEventsQueryKey = (network: 'mainnet' | 'testnet',
    runtime: Runtime,
    params?: GetRuntimeEventsParams,) => {
    return [`/${network}/${runtime}/events`, ...(params ? [params]: [])] as const;
    }

    
export const getGetRuntimeEventsQueryOptions = <TData = Awaited<ReturnType<typeof GetRuntimeEvents>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    runtime: Runtime,
    params?: GetRuntimeEventsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeEvents>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeEventsMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRuntimeEventsQueryKey(network,runtime,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetRuntimeEvents>>> = ({ signal }) => GetRuntimeEvents(network,runtime,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && runtime), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeEvents>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRuntimeEventsQueryResult = NonNullable<Awaited<ReturnType<typeof GetRuntimeEvents>>>
export type GetRuntimeEventsQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a list of runtime events.
 */
export const useGetRuntimeEvents = <TData = Awaited<ReturnType<typeof GetRuntimeEvents>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    runtime: Runtime,
    params?: GetRuntimeEventsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeEvents>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeEventsMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRuntimeEventsQueryOptions(network,runtime,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a list of EVM (ERC-20, ...) tokens on the runtime.
 */
export const GetRuntimeEvmTokens = (
    network: 'mainnet' | 'testnet',
    runtime: Runtime,
    params?: GetRuntimeEvmTokensParams,
 options?: SecondParameter<typeof GetRuntimeEvmTokensMutator>,signal?: AbortSignal
) => {
      
      
      return GetRuntimeEvmTokensMutator<EvmTokenList>(
      {url: `/${encodeURIComponent(String(network))}/${encodeURIComponent(String(runtime))}/evm_tokens`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetRuntimeEvmTokensQueryKey = (network: 'mainnet' | 'testnet',
    runtime: Runtime,
    params?: GetRuntimeEvmTokensParams,) => {
    return [`/${network}/${runtime}/evm_tokens`, ...(params ? [params]: [])] as const;
    }

    
export const getGetRuntimeEvmTokensQueryOptions = <TData = Awaited<ReturnType<typeof GetRuntimeEvmTokens>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    runtime: Runtime,
    params?: GetRuntimeEvmTokensParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeEvmTokens>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeEvmTokensMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRuntimeEvmTokensQueryKey(network,runtime,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetRuntimeEvmTokens>>> = ({ signal }) => GetRuntimeEvmTokens(network,runtime,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && runtime), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeEvmTokens>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRuntimeEvmTokensQueryResult = NonNullable<Awaited<ReturnType<typeof GetRuntimeEvmTokens>>>
export type GetRuntimeEvmTokensQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a list of EVM (ERC-20, ...) tokens on the runtime.
 */
export const useGetRuntimeEvmTokens = <TData = Awaited<ReturnType<typeof GetRuntimeEvmTokens>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    runtime: Runtime,
    params?: GetRuntimeEvmTokensParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeEvmTokens>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeEvmTokensMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRuntimeEvmTokensQueryOptions(network,runtime,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns info on an EVM (ERC-20, ...) token on the runtime.
 */
export const GetRuntimeEvmTokensAddress = (
    network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress,
 options?: SecondParameter<typeof GetRuntimeEvmTokensAddressMutator>,signal?: AbortSignal
) => {
      
      
      return GetRuntimeEvmTokensAddressMutator<EvmToken>(
      {url: `/${encodeURIComponent(String(network))}/${encodeURIComponent(String(runtime))}/evm_tokens/${encodeURIComponent(String(address))}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRuntimeEvmTokensAddressQueryKey = (network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress,) => {
    return [`/${network}/${runtime}/evm_tokens/${address}`] as const;
    }

    
export const getGetRuntimeEvmTokensAddressQueryOptions = <TData = Awaited<ReturnType<typeof GetRuntimeEvmTokensAddress>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeEvmTokensAddress>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeEvmTokensAddressMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRuntimeEvmTokensAddressQueryKey(network,runtime,address);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetRuntimeEvmTokensAddress>>> = ({ signal }) => GetRuntimeEvmTokensAddress(network,runtime,address, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && runtime && address), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeEvmTokensAddress>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRuntimeEvmTokensAddressQueryResult = NonNullable<Awaited<ReturnType<typeof GetRuntimeEvmTokensAddress>>>
export type GetRuntimeEvmTokensAddressQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns info on an EVM (ERC-20, ...) token on the runtime.
 */
export const useGetRuntimeEvmTokensAddress = <TData = Awaited<ReturnType<typeof GetRuntimeEvmTokensAddress>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeEvmTokensAddress>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeEvmTokensAddressMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRuntimeEvmTokensAddressQueryOptions(network,runtime,address,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns the list of holders of an EVM (ERC-20, ...) token.
This endpoint does not verify that `address` is actually an EVM token; if it is not, it will simply return an empty list.

 */
export const GetRuntimeEvmTokensAddressHolders = (
    network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress,
    params?: GetRuntimeEvmTokensAddressHoldersParams,
 options?: SecondParameter<typeof GetRuntimeEvmTokensAddressHoldersMutator>,signal?: AbortSignal
) => {
      
      
      return GetRuntimeEvmTokensAddressHoldersMutator<TokenHolderList>(
      {url: `/${encodeURIComponent(String(network))}/${encodeURIComponent(String(runtime))}/evm_tokens/${encodeURIComponent(String(address))}/holders`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetRuntimeEvmTokensAddressHoldersQueryKey = (network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress,
    params?: GetRuntimeEvmTokensAddressHoldersParams,) => {
    return [`/${network}/${runtime}/evm_tokens/${address}/holders`, ...(params ? [params]: [])] as const;
    }

    
export const getGetRuntimeEvmTokensAddressHoldersQueryOptions = <TData = Awaited<ReturnType<typeof GetRuntimeEvmTokensAddressHolders>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress,
    params?: GetRuntimeEvmTokensAddressHoldersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeEvmTokensAddressHolders>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeEvmTokensAddressHoldersMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRuntimeEvmTokensAddressHoldersQueryKey(network,runtime,address,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetRuntimeEvmTokensAddressHolders>>> = ({ signal }) => GetRuntimeEvmTokensAddressHolders(network,runtime,address,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && runtime && address), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeEvmTokensAddressHolders>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRuntimeEvmTokensAddressHoldersQueryResult = NonNullable<Awaited<ReturnType<typeof GetRuntimeEvmTokensAddressHolders>>>
export type GetRuntimeEvmTokensAddressHoldersQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns the list of holders of an EVM (ERC-20, ...) token.
This endpoint does not verify that `address` is actually an EVM token; if it is not, it will simply return an empty list.

 */
export const useGetRuntimeEvmTokensAddressHolders = <TData = Awaited<ReturnType<typeof GetRuntimeEvmTokensAddressHolders>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress,
    params?: GetRuntimeEvmTokensAddressHoldersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeEvmTokensAddressHolders>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeEvmTokensAddressHoldersMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRuntimeEvmTokensAddressHoldersQueryOptions(network,runtime,address,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns the list of non-fungible token (NFT) instances of an EVM (ERC-721, ...) token.
This endpoint does not verify that `address` is actually an EVM token; if it is not, it will simply return an empty list.

 */
export const GetRuntimeEvmTokensAddressNfts = (
    network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress,
    params?: GetRuntimeEvmTokensAddressNftsParams,
 options?: SecondParameter<typeof GetRuntimeEvmTokensAddressNftsMutator>,signal?: AbortSignal
) => {
      
      
      return GetRuntimeEvmTokensAddressNftsMutator<EvmNftList>(
      {url: `/${encodeURIComponent(String(network))}/${encodeURIComponent(String(runtime))}/evm_tokens/${encodeURIComponent(String(address))}/nfts`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetRuntimeEvmTokensAddressNftsQueryKey = (network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress,
    params?: GetRuntimeEvmTokensAddressNftsParams,) => {
    return [`/${network}/${runtime}/evm_tokens/${address}/nfts`, ...(params ? [params]: [])] as const;
    }

    
export const getGetRuntimeEvmTokensAddressNftsQueryOptions = <TData = Awaited<ReturnType<typeof GetRuntimeEvmTokensAddressNfts>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress,
    params?: GetRuntimeEvmTokensAddressNftsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeEvmTokensAddressNfts>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeEvmTokensAddressNftsMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRuntimeEvmTokensAddressNftsQueryKey(network,runtime,address,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetRuntimeEvmTokensAddressNfts>>> = ({ signal }) => GetRuntimeEvmTokensAddressNfts(network,runtime,address,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && runtime && address), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeEvmTokensAddressNfts>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRuntimeEvmTokensAddressNftsQueryResult = NonNullable<Awaited<ReturnType<typeof GetRuntimeEvmTokensAddressNfts>>>
export type GetRuntimeEvmTokensAddressNftsQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns the list of non-fungible token (NFT) instances of an EVM (ERC-721, ...) token.
This endpoint does not verify that `address` is actually an EVM token; if it is not, it will simply return an empty list.

 */
export const useGetRuntimeEvmTokensAddressNfts = <TData = Awaited<ReturnType<typeof GetRuntimeEvmTokensAddressNfts>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress,
    params?: GetRuntimeEvmTokensAddressNftsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeEvmTokensAddressNfts>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeEvmTokensAddressNftsMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRuntimeEvmTokensAddressNftsQueryOptions(network,runtime,address,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns the non-fungible token (NFT) instance of an EVM (ERC-721, ...) token.

 */
export const GetRuntimeEvmTokensAddressNftsId = (
    network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress,
    id: TextBigInt,
 options?: SecondParameter<typeof GetRuntimeEvmTokensAddressNftsIdMutator>,signal?: AbortSignal
) => {
      
      
      return GetRuntimeEvmTokensAddressNftsIdMutator<EvmNft>(
      {url: `/${encodeURIComponent(String(network))}/${encodeURIComponent(String(runtime))}/evm_tokens/${encodeURIComponent(String(address))}/nfts/${encodeURIComponent(String(id))}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRuntimeEvmTokensAddressNftsIdQueryKey = (network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress,
    id: TextBigInt,) => {
    return [`/${network}/${runtime}/evm_tokens/${address}/nfts/${id}`] as const;
    }

    
export const getGetRuntimeEvmTokensAddressNftsIdQueryOptions = <TData = Awaited<ReturnType<typeof GetRuntimeEvmTokensAddressNftsId>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress,
    id: TextBigInt, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeEvmTokensAddressNftsId>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeEvmTokensAddressNftsIdMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRuntimeEvmTokensAddressNftsIdQueryKey(network,runtime,address,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetRuntimeEvmTokensAddressNftsId>>> = ({ signal }) => GetRuntimeEvmTokensAddressNftsId(network,runtime,address,id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && runtime && address && id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeEvmTokensAddressNftsId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRuntimeEvmTokensAddressNftsIdQueryResult = NonNullable<Awaited<ReturnType<typeof GetRuntimeEvmTokensAddressNftsId>>>
export type GetRuntimeEvmTokensAddressNftsIdQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns the non-fungible token (NFT) instance of an EVM (ERC-721, ...) token.

 */
export const useGetRuntimeEvmTokensAddressNftsId = <TData = Awaited<ReturnType<typeof GetRuntimeEvmTokensAddressNftsId>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress,
    id: TextBigInt, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeEvmTokensAddressNftsId>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeEvmTokensAddressNftsIdMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRuntimeEvmTokensAddressNftsIdQueryOptions(network,runtime,address,id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a runtime account.
 */
export const GetRuntimeAccountsAddress = (
    network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress,
 options?: SecondParameter<typeof GetRuntimeAccountsAddressMutator>,signal?: AbortSignal
) => {
      
      
      return GetRuntimeAccountsAddressMutator<RuntimeAccount>(
      {url: `/${encodeURIComponent(String(network))}/${encodeURIComponent(String(runtime))}/accounts/${encodeURIComponent(String(address))}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRuntimeAccountsAddressQueryKey = (network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress,) => {
    return [`/${network}/${runtime}/accounts/${address}`] as const;
    }

    
export const getGetRuntimeAccountsAddressQueryOptions = <TData = Awaited<ReturnType<typeof GetRuntimeAccountsAddress>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeAccountsAddress>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeAccountsAddressMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRuntimeAccountsAddressQueryKey(network,runtime,address);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetRuntimeAccountsAddress>>> = ({ signal }) => GetRuntimeAccountsAddress(network,runtime,address, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && runtime && address), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeAccountsAddress>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRuntimeAccountsAddressQueryResult = NonNullable<Awaited<ReturnType<typeof GetRuntimeAccountsAddress>>>
export type GetRuntimeAccountsAddressQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a runtime account.
 */
export const useGetRuntimeAccountsAddress = <TData = Awaited<ReturnType<typeof GetRuntimeAccountsAddress>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeAccountsAddress>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeAccountsAddressMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRuntimeAccountsAddressQueryOptions(network,runtime,address,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns the list of non-fungible token (NFT) instances owned by an account.

 */
export const GetRuntimeAccountsAddressNfts = (
    network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress,
    params?: GetRuntimeAccountsAddressNftsParams,
 options?: SecondParameter<typeof GetRuntimeAccountsAddressNftsMutator>,signal?: AbortSignal
) => {
      
      
      return GetRuntimeAccountsAddressNftsMutator<EvmNftList>(
      {url: `/${encodeURIComponent(String(network))}/${encodeURIComponent(String(runtime))}/accounts/${encodeURIComponent(String(address))}/nfts`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetRuntimeAccountsAddressNftsQueryKey = (network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress,
    params?: GetRuntimeAccountsAddressNftsParams,) => {
    return [`/${network}/${runtime}/accounts/${address}/nfts`, ...(params ? [params]: [])] as const;
    }

    
export const getGetRuntimeAccountsAddressNftsQueryOptions = <TData = Awaited<ReturnType<typeof GetRuntimeAccountsAddressNfts>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress,
    params?: GetRuntimeAccountsAddressNftsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeAccountsAddressNfts>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeAccountsAddressNftsMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRuntimeAccountsAddressNftsQueryKey(network,runtime,address,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetRuntimeAccountsAddressNfts>>> = ({ signal }) => GetRuntimeAccountsAddressNfts(network,runtime,address,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && runtime && address), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeAccountsAddressNfts>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRuntimeAccountsAddressNftsQueryResult = NonNullable<Awaited<ReturnType<typeof GetRuntimeAccountsAddressNfts>>>
export type GetRuntimeAccountsAddressNftsQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns the list of non-fungible token (NFT) instances owned by an account.

 */
export const useGetRuntimeAccountsAddressNfts = <TData = Awaited<ReturnType<typeof GetRuntimeAccountsAddressNfts>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    runtime: Runtime,
    address: StakingAddress,
    params?: GetRuntimeAccountsAddressNftsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeAccountsAddressNfts>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeAccountsAddressNftsMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRuntimeAccountsAddressNftsQueryOptions(network,runtime,address,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns the runtime status.
 */
export const GetRuntimeStatus = (
    network: 'mainnet' | 'testnet',
    runtime: Runtime,
 options?: SecondParameter<typeof GetRuntimeStatusMutator>,signal?: AbortSignal
) => {
      
      
      return GetRuntimeStatusMutator<RuntimeStatus>(
      {url: `/${encodeURIComponent(String(network))}/${encodeURIComponent(String(runtime))}/status`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRuntimeStatusQueryKey = (network: 'mainnet' | 'testnet',
    runtime: Runtime,) => {
    return [`/${network}/${runtime}/status`] as const;
    }

    
export const getGetRuntimeStatusQueryOptions = <TData = Awaited<ReturnType<typeof GetRuntimeStatus>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    runtime: Runtime, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeStatus>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeStatusMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRuntimeStatusQueryKey(network,runtime);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetRuntimeStatus>>> = ({ signal }) => GetRuntimeStatus(network,runtime, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && runtime), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeStatus>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRuntimeStatusQueryResult = NonNullable<Awaited<ReturnType<typeof GetRuntimeStatus>>>
export type GetRuntimeStatusQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns the runtime status.
 */
export const useGetRuntimeStatus = <TData = Awaited<ReturnType<typeof GetRuntimeStatus>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    runtime: Runtime, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetRuntimeStatus>>, TError, TData>, request?: SecondParameter<typeof GetRuntimeStatusMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRuntimeStatusQueryOptions(network,runtime,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a timeline of the transaction volume at the chosen granularity,
for either consensus or one of the paratimes.

 */
export const GetLayerStatsTxVolume = (
    network: 'mainnet' | 'testnet',
    layer: Layer,
    params?: GetLayerStatsTxVolumeParams,
 options?: SecondParameter<typeof GetLayerStatsTxVolumeMutator>,signal?: AbortSignal
) => {
      
      
      return GetLayerStatsTxVolumeMutator<TxVolumeList>(
      {url: `/${encodeURIComponent(String(network))}/${encodeURIComponent(String(layer))}/stats/tx_volume`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetLayerStatsTxVolumeQueryKey = (network: 'mainnet' | 'testnet',
    layer: Layer,
    params?: GetLayerStatsTxVolumeParams,) => {
    return [`/${network}/${layer}/stats/tx_volume`, ...(params ? [params]: [])] as const;
    }

    
export const getGetLayerStatsTxVolumeQueryOptions = <TData = Awaited<ReturnType<typeof GetLayerStatsTxVolume>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    layer: Layer,
    params?: GetLayerStatsTxVolumeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetLayerStatsTxVolume>>, TError, TData>, request?: SecondParameter<typeof GetLayerStatsTxVolumeMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLayerStatsTxVolumeQueryKey(network,layer,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetLayerStatsTxVolume>>> = ({ signal }) => GetLayerStatsTxVolume(network,layer,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && layer), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetLayerStatsTxVolume>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLayerStatsTxVolumeQueryResult = NonNullable<Awaited<ReturnType<typeof GetLayerStatsTxVolume>>>
export type GetLayerStatsTxVolumeQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a timeline of the transaction volume at the chosen granularity,
for either consensus or one of the paratimes.

 */
export const useGetLayerStatsTxVolume = <TData = Awaited<ReturnType<typeof GetLayerStatsTxVolume>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    layer: Layer,
    params?: GetLayerStatsTxVolumeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetLayerStatsTxVolume>>, TError, TData>, request?: SecondParameter<typeof GetLayerStatsTxVolumeMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLayerStatsTxVolumeQueryOptions(network,layer,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Returns a (sliding) timeline of the recorded daily unique active accounts for
either consensus or one of the paratimes.

 */
export const GetLayerStatsActiveAccounts = (
    network: 'mainnet' | 'testnet',
    layer: Layer,
    params?: GetLayerStatsActiveAccountsParams,
 options?: SecondParameter<typeof GetLayerStatsActiveAccountsMutator>,signal?: AbortSignal
) => {
      
      
      return GetLayerStatsActiveAccountsMutator<ActiveAccountsList>(
      {url: `/${encodeURIComponent(String(network))}/${encodeURIComponent(String(layer))}/stats/active_accounts`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetLayerStatsActiveAccountsQueryKey = (network: 'mainnet' | 'testnet',
    layer: Layer,
    params?: GetLayerStatsActiveAccountsParams,) => {
    return [`/${network}/${layer}/stats/active_accounts`, ...(params ? [params]: [])] as const;
    }

    
export const getGetLayerStatsActiveAccountsQueryOptions = <TData = Awaited<ReturnType<typeof GetLayerStatsActiveAccounts>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(network: 'mainnet' | 'testnet',
    layer: Layer,
    params?: GetLayerStatsActiveAccountsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetLayerStatsActiveAccounts>>, TError, TData>, request?: SecondParameter<typeof GetLayerStatsActiveAccountsMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLayerStatsActiveAccountsQueryKey(network,layer,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof GetLayerStatsActiveAccounts>>> = ({ signal }) => GetLayerStatsActiveAccounts(network,layer,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(network && layer), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof GetLayerStatsActiveAccounts>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLayerStatsActiveAccountsQueryResult = NonNullable<Awaited<ReturnType<typeof GetLayerStatsActiveAccounts>>>
export type GetLayerStatsActiveAccountsQueryError = HumanReadableErrorResponse | NotFoundErrorResponse

/**
 * @summary Returns a (sliding) timeline of the recorded daily unique active accounts for
either consensus or one of the paratimes.

 */
export const useGetLayerStatsActiveAccounts = <TData = Awaited<ReturnType<typeof GetLayerStatsActiveAccounts>>, TError = HumanReadableErrorResponse | NotFoundErrorResponse>(
 network: 'mainnet' | 'testnet',
    layer: Layer,
    params?: GetLayerStatsActiveAccountsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof GetLayerStatsActiveAccounts>>, TError, TData>, request?: SecondParameter<typeof GetLayerStatsActiveAccountsMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLayerStatsActiveAccountsQueryOptions(network,layer,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




